import {ListItem} from "./list-item";
import styled from "styled-components";
import {Bag, Chest, ChestResult} from "../api/requests/model";
import {BagItem} from "./bag-item";
import {MenuSwitch} from "./menu-switch";
import React, {useState} from "react";
import {ItemInfoModal} from "./item-info-modal";


interface Props {
    data: Bag[]
    onSelect: (bag: Bag, element: HTMLDivElement | null) => void;
    selected: Bag | null
}

export const BagList = (props: Props) => {

    return (
        <GridList

        >
            {(props?.data && props?.data?.length > 12 ? props?.data : [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]).map((item, i) => {
                if (props?.data[i]?.item) {
                    return <div style={{position:'relative'}} key={i}>
                        <BagItem
                            count={props.data[i].cnt}
                            isSelected={props.selected === props.data[i]}
                            onClick={(element) => props.onSelect(props.data[i], element)}

                            image={props.data[i].item.art_url}
                        />

                    </div>
                } else {
                    return <BagItem key={i}/>
                }
            })}

        </GridList>
    )
}


const GridList = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1px;
    padding: 1px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    align-items: center;
    justify-content: center;
    width: 100%;
    justify-items: center; /* Center items horizontally within their columns */
    overflow: auto;
    padding-bottom: 70px;

`
