import React from "react";

export const ItemBox = (props:  React.SVGProps<SVGSVGElement>) =>

<svg
    {...props}
    xmlns="http://www.w3.org/2000/svg" width="105" height="106" viewBox="0 0 105 106" fill="none">
    <g opacity="0.6">
        <path d="M94.2403 104.047C66.3226 106.199 39.0102 106.199 11.1022 104.047C6.51009 103.625 2.36952 99.4842 1.95643 94.9017C-0.195518 66.9841 -0.195518 39.6717 1.95643 11.7636C2.37913 7.17154 6.51969 3.03096 11.1022 2.61786C39.0198 0.46592 66.3322 0.46592 94.2403 2.61786C98.8324 3.04057 102.973 7.18115 103.386 11.7636C105.538 39.6813 105.538 66.9937 103.386 94.9017C102.963 99.4938 98.8228 103.634 94.2403 104.047Z" fill="#104675"/>
        <path d="M11.1024 104.048C9.44041 103.836 16.5784 98.5865 16.5784 98.5865L91.8484 98.5C91.8484 98.5 96.0082 102.722 96.0562 103.683C96.1042 104.653 35.9362 107.237 11.1024 104.048Z" fill="#103E61"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.0606 7.96355C15.0606 7.96355 9.45974 3.48273 10.161 2.743C44.7267 -2.03163 95.7393 1.58056 95.1725 2.743C94.6057 3.89582 91.349 7.87709 90.1673 7.96355C89.661 8.0003 76.0203 8.00581 60.5002 8L58.5002 7.99914L56.5002 7.99828C36.5203 7.98865 15.0606 7.96355 15.0606 7.96355Z" fill="#1F6391"/>
        <path d="M56.5002 7.99828C57.8425 7.99893 58.5002 9.26152 60.5002 8L58.5002 7.99914L56.5002 7.99828Z" fill="#1F6391"/>
        <path d="M89.4413 98.5752C77.7923 99.4674 66.2612 99.9406 54.7362 99.9948C47.0778 100.031 39.4221 99.8816 31.7363 99.5475C30.7058 99.5027 28.9347 100.046 27.903 99.9948C26.7926 99.9394 26.4215 99.2885 25.3095 99.2254C22.067 99.0414 18.8175 98.8247 15.5587 98.5752C11.4802 98.1989 7.79758 94.537 7.42932 90.4713C5.52356 65.7467 5.52356 41.5545 7.42932 16.8207C7.80679 12.755 11.4802 9.08394 15.5587 8.71684C31.1354 7.52372 46.5014 7.07992 61.9236 7.38543L65.2778 7L68.1528 7.54945C75.2211 7.78158 82.3088 8.17071 89.4413 8.71684C93.5198 9.09312 97.2024 12.755 97.5707 16.8207C99.4764 41.5453 99.4764 65.7375 97.5707 90.4713C97.1932 94.537 93.5198 98.2081 89.4413 98.5752Z" fill="#105183"/>
    </g>
</svg>