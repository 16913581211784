export const Copy = (props: React.SVGProps<SVGSVGElement>) => {
    return (<svg
        {...props}
        xmlns="http://www.w3.org/2000/svg" width="27" height="33" viewBox="0 0 27 33" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M8 2V24H25V2H8ZM7 0C6.44772 0 6 0.447716 6 1V25C6 25.5523 6.44772 26 7 26H26C26.5523 26 27 25.5523 27 25V1C27 0.447715 26.5523 0 26 0H7Z"
              fill="white"/>
        <path
            d="M0 9C0 8.44772 0.447715 8 1 8H20C20.5523 8 21 8.44772 21 9V32C21 32.5523 20.5523 33 20 33H1C0.447715 33 0 32.5523 0 32V9Z"
            fill="white"/>
    </svg>)
}