import styled, {css, keyframes} from 'styled-components';
import {Deck} from "./icons/deck";
import React from "react";
import {Coin} from "./icons/coin";


export const Loader = () => {
    return (
        <Container>
            <AnimatedCoin
                isLoading={true}
                style={{
                    width: 48,
                    height: 48,
                }}
            />
        </Container>
    )
}

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const AnimatedCoin = styled(Coin)<{ isLoading: boolean }>`
  ${(props) =>
          props.isLoading &&
          css`
            animation: ${rotate} 1s linear infinite;
          `}
`

const Container = styled.div`
  display: flex;
  height: 100vh;
  scrollbar-width: none;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
    background: linear-gradient(180deg, #000 0%, #0A0F1E 100%);
  position: fixed;
  z-index: 10000000000;
  //background-image: url('/load.png');
  //background-size: cover;
  //background-repeat: no-repeat;
  //background-position: center;
  //justify-content: space-between;
  //align-items: center;


`;
const coinFlip = keyframes`
  0%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
  }
  0% {
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(1800deg);
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
  }
  100% {
    transform: rotateY(3600deg);
  }
`;

const SLoader = styled.div`
  transform: translateZ(1px);

  &:after {
    content: '$';
    display: inline-block;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    text-align: center;
    line-height: 40px;
    font-size: 32px;
    font-weight: bold;
    background: #FFD700;
    color: #DAA520;
    border: 4px double;
    box-sizing: border-box;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, .1);
    animation: ${coinFlip} 4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }
`;