import {useLayoutEffect} from 'react';
import {useClickStore} from "../store/click-store";
import {useChestStore} from "../store/chest-store";
import {tapHandler} from "../api/requests/action";

export const useSendClicksOnEveryPage = () => {

    const {clicks, setClicks} = useClickStore();
    const {setChests, activeChestId} = useChestStore()

    useLayoutEffect(() => {
        async function sendClicks() {
            if (activeChestId && clicks > 0) {
                try {
                    const resp = await tapHandler({uid: activeChestId as string, cnt: clicks});
                    if (resp.result) {
                        setChests((prev) => prev && prev.map((chest) => {
                            if (chest.uid === activeChestId) {
                                return {...chest, current_tap_cnt: resp.result.current_tap_cnt}
                            }
                            return chest
                        }))
                        setClicks(() => 0);
                    }
                } catch (e) {
                    console.error(e);
                }
            }
        }
        sendClicks();
    }, []);

    return null;
};