import styled from "styled-components";
import {BoxBG1} from "./icons/box-bg1";
import React, {useState, useEffect} from "react";
import {buildStyles, CircularProgressbar} from "react-circular-progressbar";

interface Props {
    coldDown: number;
    mutate?: () => Promise<any>;
    scale?: number;
    isGray?: boolean;

}

export const ColdDown = (props: Props) => {
    const [remainingTime, setRemainingTime] = useState(props.coldDown);

    useEffect(() => {

        if (remainingTime > 0) {
            const interval = setInterval(() => {
                setRemainingTime((prevTime) => {
                    if (prevTime <= 1) {
                        clearInterval(interval);
                        if (props.mutate) {

                            props.mutate().then(r => {
                            });
                        }
                        return 0;
                    }
                    return prevTime - 1;
                });
            }, 1000);

            return () => clearInterval(interval);
        }
    }, [remainingTime]);

    const formatTime = (seconds: number) => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const secs = Math.floor(seconds % 60);

        if (hours > 0) {
            return `${hours} ч. ${minutes} мин.`;
        } else if (minutes > 0) {
            return `${minutes} м. ${secs} сек.`;
        } else {
            return `${secs} сек.`;
        }
    };

    const formattedColdDown = formatTime(remainingTime);


    if (props.isGray) {
        return (
            <Relative scale={props.scale}>
                <svg xmlns="http://www.w3.org/2000/svg" width="197" height="197" viewBox="0 0 197 197" fill="none">
                    <circle cx="98.5" cy="98.5" r="97" fill="#5E5E5E" stroke="#828282" stroke-width="3"/>
                    <circle cx="98.5" cy="98.5" r="80.5281" fill="url(#paint0_radial_14_2963)"/>
                    <circle cx="98.5" cy="98.5" r="80.5281" fill="black" fill-opacity="0.79"
                        // style="mix-blend-mode:color"
                            style={{mixBlendMode: 'color'}}
                    />
                    <circle cx="98.5" cy="98.5" r="78.0281" stroke="#1D1C20" stroke-opacity="0.32" stroke-width="5"/>
                    <defs>
                        <radialGradient id="paint0_radial_14_2963" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                        gradientTransform="translate(98.5 98.5) rotate(90) scale(80.5281)">
                            <stop stop-color="#4C5582"/>
                            <stop offset="1" stop-color="#2D2626"/>
                        </radialGradient>
                    </defs>
                </svg>
                <GrayText
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        zIndex: 3,
                    }}
                >{formattedColdDown}</GrayText>

            </Relative>
        )
    }

    return (
        <Relative
            scale={props.scale}
        >
            <Outline isGray={props.isGray}>
                <svg style={{height: 0, width: 0}}>
                    <defs>
                        <linearGradient id="gradientColors" gradientTransform="rotate(90)">
                            <stop offset="0%" stopColor="#E8514A"/>
                            <stop offset="100%" stopColor="#FFC25F"/>
                        </linearGradient>
                    </defs>
                </svg>
                <CircularProgressbar
                    value={0}
                    styles={buildStyles({
                            pathColor: 'url(#gradientColors)',
                            textColor: '#f88',
                            backgroundColor: '#2c334f',
                            trailColor: '#2c334f',

                        },
                    )}
                />
                <svg
                    style={{
                        position: 'absolute',
                        top: '25px',
                        left: '25px',
                        width: '273px',
                        height: '273px',
                    }}

                    xmlns="http://www.w3.org/2000/svg" width="224" height="224" viewBox="0 0 224 224" fill="none">
                    <circle cx="112" cy="112" r="109.583" stroke="#272647" stroke-width="4"/>
                </svg>

                <Text>{formattedColdDown}</Text>
            </Outline>
        </Relative>
    );
};

const Text = styled.div`
    font-size: 35px;
    line-height: 42px;
    text-align: center;
    color: #ffffff;
    font-weight: 500;
    position: absolute;
    z-index: 3;
`;


const Outline = styled.div<{ isClicked?: boolean; isGray?: boolean }>`
    width: 320px;
    height: 320px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    //border: 1px solid #A7C3F0;
    //background: radial-gradient(50% 50% at 50% 50%, #6371B1 0%, #2A304B 100%);
    background: ${(props) => props.isGray ? '#2c334f' : 'radial-gradient(50% 50% at 50% 50%, #6371B1 0%, #2A304B 100%)'};
    border: ${(props) => props.isGray ? '1px solid #2c334f' : '1px solid #A7C3F0'};
    position: relative;

`;

const Relative = styled.div<{ scale?: number }>`
    position: relative;
    scale: ${(props) => props.scale};
`;


const GrayText = styled.div`
    color: #FFF;
    text-align: center;
    text-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    -webkit-text-stroke-width: 2;
    -webkit-text-stroke-color: #404049;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 133.333% */
    width: 100%;
`
