import styled from "styled-components";
import {Header} from "../components/header";
import {ReferralItem} from "../components/referral-item";
import {Button} from "../components/button";
import {Copy} from "../components/icons/copy";
import {Menu} from "../components/menu";
import {PrizeItem} from "../components/prize-item";
import {useRefList} from "../api/requests/refs";
import {Loader} from "../components/loader";
import {useMeStore} from "../store/me-store";
import {useSendClicksOnEveryPage} from "../hooks/useSendClicksOnEveryPage";
import React from "react";
import {ButtonTooltip} from "../components/tooltip";
import {useCopy} from "../hooks/use-copy";

export const ReferralPage = () => {

    const {data: levelUps, isLoading: isLoading1} = useRefList('referral_levelup')
    const {data: referrals, isLoading: isLoading2} = useRefList('new_referral')

    const {user} = useMeStore()

    useSendClicksOnEveryPage()
    const [isCopied, handleCopy] = useCopy(user?.ref_link || '')

    if (isLoading1 || isLoading2) return <Loader/>

    return (
        <Container>
            <Header user={user || null}/>
            <Scroll>
                <Title>
                    Пригласите друзей!
                </Title>
                <P>
                    Вы и ваш друг получат бонусы
                </P>
                <PrizeItem prize={3000} title={'Пригласить друга'}/>
                <PrizeItem prize={15000} title={'Пригласить друга с Telegram Premium'}/>
                {levelUps?.result?.data && levelUps?.result?.data?.length > 0 &&
                    <>
                        <H2>{`Бонус за повышение уровня`}</H2>
                        {levelUps?.result?.data?.map((item, index) => (
                            <ReferralItem type={'referral_levelup'} referral={item} key={index}/>
                        ))}
                    </>
                }
                <Text>{`Список ваших друзей (${referrals?.result?.data?.length ? referrals?.result?.data?.length : 0})`}</Text>
                {(referrals?.result?.data && referrals?.result?.data?.length > 0) ?
                    <>
                        {referrals?.result?.data?.map((item, index) => (
                            <ReferralItem type={'new_referral'} referral={item} key={index}/>
                        ))}
                    </> :
                    <EmptyItem>Вы ещё никого не пригласили</EmptyItem>
                }
                <ButtonContainer>
                    <ButtonTooltip visible={!isCopied}/>
                    <Button
                        animated
                        width={'78%'}
                        onClick={() => {
                            window.location.href = `https://t.me/share/url?url=${user?.ref_link}`
                        }}
                        variant={'large'}>
                        Пригласить друга
                    </Button>
                    <Button
                        width={'66px'}
                        onClick={() => {
                            handleCopy()
                        }}
                        icon={<Copy style={{marginTop: 8}}/>}
                        variant={'small'}>
                    </Button>
                </ButtonContainer>
            </Scroll>
            <Menu isAbsolute/>
        </Container>
    )
}


const H2 = styled.div`
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    color: white;
    margin-top: 16px;
    margin-bottom: 8px;
`

const Text = styled.div`
    font-size: 16px;
    font-weight: 500;
    line-height: 19.2px;
    text-align: left;
    color: var(--Text_2, rgba(204, 208, 212, 1));
    margin-top: 16px;
    margin-bottom: 4px;
    margin-left: 8px;

`

const Title = styled.div`
    color: #FFF;
    text-align: center;

    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px; /* 125% */
`
const P = styled.div`
    color: #FFF;
    text-align: center;

    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */

`

const Container = styled.div`
    display: flex;
    height: 100vh;
    width: 100%;
    background: #1d4163;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    position: relative;
    overflow: hidden;
`;

const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
    margin-top: 10px;
    width: 100%;
    box-sizing: border-box;
    justify-content: space-between;
    position: relative;
`;


const Scroll = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow-y: auto;
    width: 100%;
    padding: 20px 20px 100px 20px;
    height: 92%;
    box-sizing: border-box;
    background-image: url('/bg/main-bg.png');

`;


const EmptyItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: rgba(133, 133, 133, 0.42);
    border-radius: 10px;
    box-sizing: border-box;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.2px;
    color: rgba(255, 255, 255, 1);
    padding: 20px;
    text-align: center;
`
