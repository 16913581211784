import {useEffect, useState} from "react";
export const useToken = () => {
    const [token, setToken] = useState<string | null>(null);
    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            setToken(token);
        }
    }, [localStorage.getItem('token')]);
    return token;
}