import {useSendClicksOnEveryPage} from "../hooks/useSendClicksOnEveryPage";
import {useParams} from "react-router";
import {useMeStore} from "../store/me-store";
import {useNavigate} from "react-router-dom";
import {Header} from "../components/header";
import {Button} from "../components/button";
import {Menu} from "../components/menu";
import styled, {keyframes} from "styled-components";
import {Copy} from "../components/icons/copy";
import {useCopy} from "../hooks/use-copy";
import {ButtonTooltip} from "../components/tooltip";
import {Lopata} from "../components/icons/lopata";

export const Digger = () => {

    useSendClicksOnEveryPage()
    const {user} = useMeStore()
    const nav = useNavigate()
    const [isCopied, handleCopy] = useCopy(user?.ref_link || '')


    return (
        <Container>
            <Header isWithoutPadding user={user}/>
            <Column>

                <Title>
                    Раскопка
                </Title>
                <P color={'#FFF'}>
                    Раскапывайте каналы в Телеграм и получайте за это <P color={'#FFDA00'}>особые сундуки</P> с ценными наградами!
                </P>

            </Column>
            <Lopata style={{width: '70%', height: 'auto'}}/>
            <P color={'#777'}>
                Вставьте ссылку в Телеграм канал (или найдите чужую), перейдите по ней и получите сундук
            </P>
            <ButtonCol>
                <ButtonRow>
                    <Button
                        height={'54px'}
                        onClick={() => {
                            nav('/rules/1')
                        }}
                        type={'secondary'}>
                        Правила
                    </Button>
                    <Button
                        height={'54px'}
                        type={'secondary'}
                        onClick={() => {
                            nav('/partner/1')
                        }}
                    >
                        {'Партнёрка'}
                    </Button>
                </ButtonRow>

                <Relative>
                    <ButtonTooltip visible={!isCopied}/>
                    <Button
                        onClick={() => {
                            handleCopy()
                        }}
                        height={'54px'}>
                        <Copy style={{marginRight: 4}}/>
                        Ссылка на раскопку
                    </Button>
                </Relative>
            </ButtonCol>
            <Menu isAbsolute/>

        </Container>
    )
}


const Relative = styled.div`
    position: relative;
    width: 100%;
    box-sizing: border-box;
`


const ButtonCol = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 10px;
`

const Img = styled.img`
    width: 200px;
    height: 200px;
    top: -17.46px;
    left: -0.61px;


`

const ButtonRow = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    gap: 10px;
`


const Column = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 10px;
`

const P = styled.span<{ color: string }>`
    color: ${props => props.color};
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;   
`


const Title = styled.div`
    color: #FFF;
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px; /* 125% */

`

const Container = styled.div`
    background: linear-gradient(180deg, #000 0%, #0A0F1E 100%);
    display: flex;
    height: 100vh;
    scrollbar-width: none;
    width: 100%;

    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    position: relative;
    padding: 16px;
    padding-bottom: 90px;
    box-sizing: border-box;
`;
