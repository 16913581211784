import React, {forwardRef, useImperativeHandle, useRef} from 'react';
import styled from "styled-components";
import {ChestResult} from "../../api/requests/model";
import {ShineBG} from "../icons/shine-bg";
import {Shine} from "../icons/shine";
import Lottie from "lottie-react";
import animation from '../../animation/coins.json'
import {buildStyles, CircularProgressbar} from "react-circular-progressbar";
import 'react-circular-progressbar/dist/styles.css';
import {getGradientsById} from "../../utils/get-gradients-by-id";

export const MainChestRef = forwardRef<{
    shiver: () => void
}, {
    isChestOpen: boolean;
    chest: ChestResult | null;
    total: number;
    chestCount: number;
}>((props, ref) => {


    const transformTimer = useRef<any>(undefined);
    useImperativeHandle(ref, () => ({
        shiver() {
            if (!transformTimer.current) {
                transformTimer.current = setTimeout(() => {
                    clearTimeout(transformTimer.current);
                    transformTimer.current = undefined;
                }, 500);
            }
        }
    }));


    return (
        <>
            <Outline>
                <svg style={{height: 0, width: 0}}>
                    <defs>
                        <linearGradient id="gradientColors" gradientTransform="rotate(90)">
                            <stop offset="0%" stopColor={getGradientsById(props.chest?.chest.id)[0]}/>
                            <stop offset="100%" stopColor={getGradientsById(props.chest?.chest.id)[1]}/>
                        </linearGradient>
                    </defs>
                </svg>
                <CircularProgressbar
                    value={props.chestCount / props.total * 100}

                    styles={buildStyles({
                            pathColor: 'url(#gradientColors)',
                            textColor: '#f88',
                            backgroundColor: '#2c334f',
                            trailColor: '#2c334f',

                        },
                    )}
                />
                <svg
                    style={{
                        position: 'absolute',
                        top: '25px',
                        left: '25px',
                        width: '273px',
                        height: '273px',
                    }}

                    xmlns="http://www.w3.org/2000/svg" width="224" height="224" viewBox="0 0 224 224" fill="none">
                    <circle cx="112" cy="112" r="109.583" stroke="#272647" stroke-width="4"/>
                </svg>
                <>
                    {props.isChestOpen && (
                        <div style={{zIndex: 3, position: 'absolute'}}>
                            <Relative>
                                <ShineBG style={{zIndex: 3}}/>
                                <Shine style={{
                                    zIndex: 90,
                                    position: 'absolute',
                                    top: '0',
                                    left: '0',
                                    right: '0',
                                    bottom: '0',
                                    margin: 'auto'
                                }}/>
                            </Relative>
                        </div>
                    )}

                    {props.isChestOpen ?
                        <Img


                            src={props?.chest?.chest?.open_art_url}
                            alt={'chest'}

                        /> :
                        <Img

                            src={props?.chest?.chest?.art_url}
                            alt={'chest'}
                        />}
                    <FakeImg/>
                </>
                {props.isChestOpen && (
                    <Lottie
                        animationData={animation}
                        style={{
                            position: 'absolute',
                            top: '-70px',
                            margin: 'auto',
                            width: '400px',
                            height: '400px',
                            zIndex: 6
                        }}/>
                )}
            </Outline>

        </>

    );
});


const FakeImg = styled.div`
    width: 250px;
    height: 250px;
    position: absolute;
    z-index: 4;
`


const Img = styled.img`
    width: 250px;
    height: 250px;
    position: absolute;
    top: 34px;
    left: 38px;
    z-index: 4;
    touch-action: none;
    user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;

    -webkit-touch-callout: none;
`;


const Outline = styled.div<{ isClicked?: boolean }>`
    width: 320px;
    height: 320px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #A7C3F0;
    background: radial-gradient(50% 50% at 50% 50%, #6371B1 0%, #2A304B 100%);
    position: relative;


`;

const Relative = styled.div`
    position: relative;


    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;
