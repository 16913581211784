import React from "react";

export const Lock = (props:React.SVGProps<SVGSVGElement>) => {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
                d="M19.5 10H4.83333C3.82042 10 3 10.8204 3 11.8333V21C3 22.0129 3.82042 22.8333 4.83333 22.8333H19.5C20.5129 22.8333 21.3333 22.0129 21.3333 21V11.8333C21.3333 10.8204 20.5129 10 19.5 10ZM12.1667 18.25C11.1537 18.25 10.3333 17.4296 10.3333 16.4167C10.3333 15.4037 11.1537 14.5833 12.1667 14.5833C13.1796 14.5833 14 15.4037 14 16.4167C14 17.4296 13.1796 18.25 12.1667 18.25Z"
                fill="white"/>
            <path
                d="M17.667 8.33334H15.8337V6.50001C15.8547 4.49801 14.2506 2.85718 12.2486 2.83334H12.2101C10.1962 2.79851 8.53516 4.40176 8.50032 6.41567V6.41842V8.33334H6.66699V6.40834C6.69724 3.41634 9.12824 1.00551 12.1202 1.00001H12.1762C15.203 0.994509 17.6615 3.44384 17.667 6.47067C17.667 6.48351 17.667 6.49634 17.667 6.50917V8.33334Z"
                fill="white"/>
        </svg>
    )
}