import styled, {keyframes} from "styled-components";

export const ButtonTooltip = ({visible}: { visible: boolean }) => {

    if (visible) return null

    return (
        <Tooltip visible={visible}>
            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="19" viewBox="0 0 21 19" fill="none">
                <rect x="0.5" y="0.5" width="20" height="18" rx="9" fill="#28BA44"/>
                <rect x="0.5" y="0.5" width="20" height="18" rx="9" stroke="#224A0D"/>
                <path
                    d="M19 7.04045C18.443 6.10112 17.2564 4.79101 16.1425 4C14.5199 5.5573 11.8077 8.9191 9.99145 11.4899C8.22365 8.69663 6.28633 6.59551 4.59117 5.13708C3.62251 5.92809 2.48433 7.60899 2 8.72135C4.39744 10.3281 7.25499 12.9236 9.16809 15C10.4274 14.7034 10.8875 14.6045 11.7835 14.6045C14.2293 11.6135 16.4088 9.48764 19 7.04045Z"
                    fill="#1E4910"/>
            </svg>

            Скопировано!

        </Tooltip>
    )
}

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`;


const Tooltip = styled.div<{ visible: boolean }>`
  display: flex;
  font-size: 16px;
  font-style: italic;
  font-weight: 700;
  line-height: 19.2px;
  text-align: left;
  gap: 4px;
  background: red;
  position: absolute;
  top: -56%;
  z-index: 100;
  padding: 4px 8px;
  border-radius: 4px;
  width: 100%;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.24), rgba(255, 255, 255, 0.24)),
  linear-gradient(0deg, rgba(255, 255, 255, 0.42), rgba(255, 255, 255, 0.42));
  box-sizing: border-box;
  animation: ${fadeIn} 0.3s ease-in-out;
 
`
