import useSWR from "swr";
import {fetchSWR} from "../apiClient";
import {Bag, ChestResult, Response} from "./model";


export const useGallery = (skip?: boolean) => {
    return useSWR(skip ? undefined :
            '/api/static',
        (url: any) => fetchSWR<Response<{
            chests: ChestImage[],
            items: ItemImage[],
            cards: ItemImage[],
        }>>({input: url}), {
            revalidateOnFocus: false,
        }
    );
}


interface ChestImage {
    id: number;
    art_url: string;
    open_art_url: string;

}


interface ItemImage {
    id: number;
    art_url: string;
    artefact_art_url: string;

}
