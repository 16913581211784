import styled, {css} from "styled-components";
import React from "react";
import {Level} from "../components/level";
import {Profile as ProfileIcon} from "../components/icons/profile";
import {TonConnectButton, useTonAddress} from "@tonconnect/ui-react";
import {Ton} from "../components/icons/ton";
import {Menu} from "../components/menu";
import {useSendClicksOnEveryPage} from "../hooks/useSendClicksOnEveryPage";
import {Link} from "react-router-dom";
import {useBackendAuth} from "../hooks/use-ton-auth";
import {useUserInfo} from "../api/requests/user-info";

const numberWithCommas = (x: number) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
export const Profile = () => {
    const {data: user} = useUserInfo()
    const tonAddress = useTonAddress()
    useSendClicksOnEveryPage()

    useBackendAuth()


    return (
        <Container>
            <Col>
                <ProfileIcon style={{
                    width: 80,
                    height: 80,
                }}/>
                <UserNickname>
                    {user?.result?.username || user?.result?.first_name || user?.result?.tg_id || 'User'}
                </UserNickname>
                <Level
                    exp={user?.result?.level_info?.exp || 0}
                    expTo={user?.result?.level_info?.exp_to || 0}
                    level={user?.result?.level_info?.level || 1}
                />
                <Row>
                    <Balance>
                        <img
                            src="/images/icon-coin.png"
                            style={{
                                width: 30,
                                height: 30,
                            }}
                        />
                        {numberWithCommas(user?.result?.coin_cnt || 0)}
                    </Balance>
                    <Balance>
                        <Ton
                        />0
                    </Balance>
                </Row>
                <TonConnectButtonWrapper $isConnected={!!tonAddress}>
                    <TonConnectButton
                        style={{
                            width: !!tonAddress ? 'unset' : '100%',
                            height: '52px !important',
                            fontSize: '38px !important',
                            fontWeight: 700,
                            float: 'right',
                        }}
                    />
                </TonConnectButtonWrapper>
                <Link
                    style={{
                        textDecoration: 'none',
                        width: '100%',
                    }}
                    to={'/settings'}>

                    <SettingsButton>
                        Настройки
                    </SettingsButton>
                </Link>
            </Col>
            <Menu isAbsolute/>

        </Container>
    )
}


const TonConnectButtonWrapper = styled.div<{ $isConnected?: boolean }>`
    width: 100% !important;
    display: flex;
    justify-content: center;
    ${({$isConnected}) =>
            !$isConnected &&
            css`
                & button {
                    border-radius: 16px;
                    border: 1px solid #84CCFF;
                    background: linear-gradient(180deg, #68B5EC 0%, #2588CF 95.97%);
                    box-shadow: 0px 3px 0px 0px #1D689F;
                    display: flex;
                    padding: 12px;
                    justify-content: center;
                    align-items: center;
                    align-self: stretch;
                    width: 100% !important;
                    height: 52px !important;
                    color: #FFF;
                    text-align: center;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 700;
                }

                & div {
                    color: #FFF;
                    text-align: center;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 28px; /* 155.556% */
                    width: auto;
                }
            `}
`;


const Container = styled.div`
    display: flex;
    height: 100vh;
    scrollbar-width: none;
    overflow: hidden;
    width: 100%;
    background-image: url('/bg/main-bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    position: relative;
    box-sizing: border-box;
    padding: 48px 16px;
`;


const UserNickname = styled.div`
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
`


const SettingsButton = styled.div`
    border-radius: 16px;
    border: 1px solid #224A73;
    background: linear-gradient(180deg, #1C3F63 0%, #112C49 95.97%);
    box-shadow: 0px 3px 0px 0px #0C2135;
    display: flex;
    padding: 0 12px;
    height: 52px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    color: #FFF;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; /* 155.556% */
`

const Col = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    justify-content: center;
`


const Balance = styled.div`
    color: #FFF;
    text-align: center;
    display: flex;
    align-items: center;
    gap: 7px;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 133.333% */
`

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 16px;
`