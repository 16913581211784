import styled, {css, keyframes} from "styled-components";
import React from "react";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    onClick?: () => void;
    icon?: React.ReactNode;
    children?: React.ReactNode;
    variant?: 'small' | 'large';
    width?: string;
    height?: string;
    type?: 'primary' | 'secondary';
    animated?: boolean;
    loading?: boolean;
}

export const Button = (props: Props) => {
    return (
        <Relative
            animated={props.animated}
            width={props.width}
        >
            <StyledButton3 height={props.height}
                           type={props.type || 'primary'}/>

            <StyledButton2
                type={props.type || 'primary'}
                height={props.height}
                {...props}
                onClick={props.onClick}>
                {props.icon &&
                    <IContent>
                        {props.icon}
                    </IContent>
                }
                {props.loading
                    ? <Loader/>
                    : props.children}

            </StyledButton2>
        </Relative>
    )
}

const pulse = keyframes`
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.04);
    }
    100% {
        transform: scale(1);
    }
`;


const Relative = styled.div<{ width?: string; height?: string; animated?: boolean }>`
    position: relative;
    width: ${props => props.width ? props.width : '100%'};
    box-sizing: border-box;
    height: ${props => props.height ? props.height : 'auto'};
    ${props => props.animated && css`
        animation: ${pulse} 1.5s infinite;
    `}

`
const StyledButton2 = styled.div<{ type?: 'primary' | 'secondary'; height?: string }>`
            background: ${props => props.type === 'primary' ? 'linear-gradient(180deg, #83DA0F 0%, #1EB649 95.97%)' : 'linear-gradient(180deg, #FFDA00 0%, #F7B812 90.48%)'};
            border-radius: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
            height: ${props => props.height ? props.height : '54px'};
            position: relative;
            box-sizing: border-box;
            z-index: 1;
            text-align: center;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 28px; /* 155.556% */
    `
;

const StyledButton3 = styled.div<{ type?: 'primary' | 'secondary'; height?: string }>`
    //background: linear-gradient(0deg, #158832, #158832);
    background: ${props => props.type === 'primary' ? 'linear-gradient(0deg, #158832, #158832)' : 'linear-gradient(180deg, #B5370F 0%, #B17111 100%)'};
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    position: absolute;
    bottom: -6px;
    width: 100%;
    height: ${props => props.height ? props.height : '54px'};
    z-index: 0;

`


const flash = keyframes`
  0% {
    background-color: #FFF2;
    box-shadow: 32px 0 #FFF2, -32px 0 #FFF;
  }
  50% {
    background-color: #FFF;
    box-shadow: 32px 0 #FFF2, -32px 0 #FFF2;
  }
  100% {
    background-color: #FFF2;
    box-shadow: 32px 0 #FFF, -32px 0 #FFF2;
  }
`;

const Loader = styled.span`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 32px 0 #fff, -32px 0 #fff;
  position: relative;
  animation: ${flash} 0.5s ease-out infinite alternate;
 `;


const IContent = styled.div`

`