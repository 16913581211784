import {create} from 'zustand';

type LoadedState = {
    loading: boolean;
    setLoading: (loading: boolean) => void;
};

export const useImageLoadStore = create<LoadedState>((set) => ({
    loading: true,
    setLoading: (loading) => set({loading: loading}),
}));