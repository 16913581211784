export function getTap2CoinX2EventCnt(tapToCoinX2Chance: number, currentTapCnt: number, increaseTapCnt: number) {
    if (tapToCoinX2Chance === 0) {
        return 0;
    }

    const tapsForEvent = Math.floor(1 / tapToCoinX2Chance);
    const endTap = currentTapCnt + increaseTapCnt;
    let tap2CoinX2EventCnt = 0;

    for (let i = currentTapCnt; i < endTap; i++) {
        if (i !== 0 && i % tapsForEvent === 0) {
            tap2CoinX2EventCnt++;
        }
    }

    return tap2CoinX2EventCnt;
}
