// get-gradients-by-id


export function getGradientsById(id?: number) {
    if (id === 1) {
        return ['#FFD27B', '#FFB800']
    } else if (id === 2) {
        return ['#E8514A', '#FFC25F']
    } else if (id === 3) {
        return ['#7B61FF', '#FF51D8']
    } else if (id === 4) {
        return ['#FFC55E', '#00FFC2']
    } else if (id === 5) {
        return ['#BC9C9F', '#03B7FD']
    }
    return ['#fff', '#fff']
}