import {fetchSWR, post} from "../apiClient";
import useSWR from "swr";
import {Response, State, User} from "./model";


export const useUserInfo = (skip?: boolean) => {
    return useSWR(skip ? undefined :
            '/api/me',
        (url: any) => fetchSWR<Response<User>>({input: url}), {
            revalidateOnFocus: false
        }
    );
}


export interface SetUp {
    chests: ChestInfo[];
}

export const useSetUp = (skip?: boolean) => {
    return useSWR(skip ? undefined :
            '/api/setup',
        (url: any) => fetchSWR<Response<SetUp>>({input: url}), {
            revalidateOnFocus: false
        }
    );
}


export interface ChestInfo {
    id: number
    title: string
    art_url: string
    open_art_url: string
    open_tap_cnt: number
    unlock_coin_cnt: number
    reward_coin_cnt: {
        min: number
        max: number
    }
    reward_item_chance: number
    type: string
}


export async function diggerInfo(initDataRaw: string) {
    const response = await post({
        input: '/api/play/dig',
        init: {
            headers: {
                'Content-Type': 'application/json',
            }
        },
        data: {
            init_data: initDataRaw
        }
    });
    return (await response.json()) as Response<State>;
}


export async function authHandler(initDataRaw: string) {
    const response = await post({
        input: '/api/auth',
        init: {
            headers: {
                'Content-Type': 'application/json',
            }
        },
        data: {
            init_data: initDataRaw
        }
    });
    return (await response.json()) as any;
}

