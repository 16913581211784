import {useSendClicksOnEveryPage} from "../../hooks/useSendClicksOnEveryPage";
import {Menu} from "../../components/menu";
import styled from "styled-components";
import {useParams} from 'react-router';
import {Header} from "../../components/header";
import {useMeStore} from "../../store/me-store";
import {Button} from "../../components/button";
import {useNavigate} from "react-router-dom";
import {Lopata} from "../../components/icons/lopata";
import {Chests} from "../../components/icons/chests";


const rules = [
    {
        title: "Добро пожаловать",
        description: 'Вы перешли по ссылке в Телеграм канале \n' +
            'и активировали процесс раскопки',
     },
    {
        title: "Сундуки",
        description: 'Если вы первым перешли по ссылке, то вы получите 1 из 3 сундуков ',
     },
    {
        title: "Время ваш друг",
        description: 'Если вы не успели, просто вернитесь через время и используйте ту же ссылку!',
     },
    {
        title: "Хотите быть первым?",
        description: 'Запостите самостоятельно ссылку в  любой Телеграм канал и перейти по неё!',
     },
    {
        title: "Больше - лучше",
        description: `Чем больше в Телеграм канале людей, тем более ценный сундук можно откопать!`,
     },
]

export const EnterRule = () => {

    useSendClicksOnEveryPage()
    const {id} = useParams();
    const {user} = useMeStore()
    const nav = useNavigate()


    return (
        <Container>
            <Header isWithoutPadding user={user}/>
            {Number(id) === 1 ?
            <Lopata
                 style={{
                    width: '75%',
                     height: 'auto',
                }}
            />
                : <Chests
                    style={{
                        width: '86%',
                        height: 'auto',
                    }}

                />
            }
            <Column>
                <Row>
                    {[0, 1, 2, 3, 4].map((item) => {
                        return <Step key={item} title={item + 1} isActivated={Number(id) === item + 1}/>
                    })}
                </Row>
                <Title>
                    {rules[Number(id) - 1].title}
                </Title>
                <P>
                    {rules[Number(id) - 1].description}
                </P>
            </Column>
            <ButtonCol>
                <Button
                    onClick={() => {
                        if (Number(id) < 5) {
                            nav(`/enter-rules/${Number(id) + 1}`)
                        } else {
                            nav(`/`)
                        }
                    }}
                >
                    Продолжить
                </Button>
                <Skip onClick={() => nav(`/`)}>
                    Пропустить
                </Skip>
            </ButtonCol>

        </Container>
    )
}


const ButtonCol = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    gap: 20px;
    padding-bottom: 32px;
`


const Column = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 10px;
`

const P = styled.div`
    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    opacity: 0.6;
 `


const Row = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 12px;
    margin-bottom: 8px;
`

const Title = styled.div`
    color: #FFF;
    text-align: center;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
`

const Container = styled.div`
    display: flex;
    height: 100vh;
    scrollbar-width: none;
    width: 100%;
    background: linear-gradient(180deg, #000 0%, #1E130A 100%);
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    position: relative;
    padding: 16px;
    box-sizing: border-box;
`;


interface StepProps {
    title: number;
    isActivated: boolean;
}

const Step = (props: StepProps) => {

    if (!props.isActivated) {
        return (
            <StepInActive>
                {props.title}
            </StepInActive>
        )
    }


    return (
        <StepWrapper>
            <StepInner>
                <StepTitle>{props.title}</StepTitle>
            </StepInner>
        </StepWrapper>
    )
}


const StepInActive = styled.div`
    border-radius: 16px;
    background: #261A0E;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;

    color: #7E5D3B;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

`


const StepWrapper = styled.div`
    width: 32px;
    height: 32px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 1px solid #9C4E26;
    box-sizing: border-box;
`


const StepInner = styled.div`
    display: flex;
    width: 24px;
    height: 24px;
    box-sizing: border-box;

    padding: 3px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 50%;
    border: 2px solid rgba(255, 179, 145, 0.30);
    background: radial-gradient(50% 50% at 50% 50%, #8B431B 0%, #F17846 100%);
`


const StepTitle = styled.div`
    color: #FFF;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
`


const Skip = styled.div`
    color: rgba(255, 255, 255, 0.40);
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
`