import React from "react";
import styled from "styled-components";
import {useSpring, animated} from "@react-spring/web";
import {Link} from "react-router-dom";

interface Props {
    balance: number;
}

// Add comma between every 3 digits
const numberWithCommas = (x: number) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const Balance = (props: Props) => {
    const {balance} = props;
    const widthRef = React.useRef<HTMLDivElement>(null);

    const {animatedBalance} = useSpring({
        animatedBalance: balance,
        config: {tension: 150, friction: 20}, // Reduced tension and friction
    });

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                position: "relative",
            }}
        >
            <Link to={'/leaderboard'} style={{position: "absolute", left: 16, zIndex: 1}}>
                <svg
                    opacity="0.5"
                    xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                    <path
                        d="M11.7831 26.237L10.1451 27.8336L10.5318 30.0882C10.7608 31.4206 9.36205 32.4381 8.16315 31.8088L6.13853 30.7442L4.11392 31.8088C2.91821 32.4376 1.51725 31.4236 1.74531 30.0882L2.13197 27.8336L0.494007 26.237C-0.473769 25.2937 0.0608843 23.6468 1.39865 23.4524L3.66259 23.1237L4.67457 21.0724C5.27389 19.8594 7.00338 19.8599 7.6025 21.0724L8.61447 23.1237L10.8784 23.4524C12.2145 23.6465 12.753 25.2915 11.7831 26.237Z"
                        fill="white"/>
                    <path opacity="0.5"
                          d="M0.798654 11.9284L1.36531 11.6191L1.67463 11.0524C2.25588 9.98913 3.78785 9.98893 4.36923 11.0524L4.67922 11.6191L5.24521 11.9284C6.30851 12.5097 6.30871 14.0416 5.24521 14.623L4.67922 14.933L4.36923 15.499C3.78798 16.5623 2.25602 16.5625 1.67463 15.499L1.36531 14.933L0.798654 14.623C-0.26472 14.0417 -0.264786 12.5098 0.798654 11.9284Z"
                          fill="white"/>
                    <path opacity="0.5"
                          d="M26.754 14.3199L27.32 14.0106L27.63 13.4439C28.0452 12.6845 29.0102 12.4161 29.7606 12.8599C30.1995 13.1203 30.3153 13.4269 30.6339 14.0106L31.2006 14.3199C32.2639 14.9026 32.2643 16.433 31.2006 17.0145L30.6339 17.3245L30.3246 17.8905C29.7433 18.9539 28.2114 18.9539 27.63 17.8905L27.32 17.3245L26.754 17.0145C25.691 16.4334 25.6901 14.9029 26.754 14.3199Z"
                          fill="white"/>
                    <path
                        d="M20.2015 20.023L15.9996 17.8144L11.7977 20.023C10.2309 20.8479 8.39619 19.5146 8.69578 17.769L9.49843 13.0905L6.09918 9.77723C4.83088 8.54206 5.53126 6.38578 7.28382 6.13065L11.9817 5.448L14.0823 1.19144C14.8659 -0.397382 17.1335 -0.396915 17.9169 1.19144L20.0175 5.448L24.7154 6.13065C26.471 6.38618 27.1661 8.54413 25.9 9.77723L22.5008 13.0905L23.3034 17.769C23.603 19.5147 21.7691 20.8471 20.2015 20.023Z"
                        fill="white"/>
                    <path
                        d="M31.2252 26.2369L29.5873 27.8336L29.9739 30.0882C30.203 31.4208 28.8023 32.4385 27.606 31.8088L25.5807 30.7441L23.5561 31.8088C22.3562 32.4398 20.9596 31.418 21.1882 30.0882L21.5748 27.8336L19.9369 26.2369C18.9678 25.2938 19.5032 23.6468 20.8415 23.4523L23.1048 23.1237L24.1174 21.0724C24.7153 19.8592 26.4472 19.8601 27.0447 21.0724L28.0573 23.1237L30.3206 23.4523C31.6592 23.6469 32.194 25.2941 31.2252 26.2369Z"
                        fill="white"/>
                </svg>

            </Link>
            <BalanceWrapper>
                <Coin
                    src="/images/icon-coin.png"
                    alt="coin"
                />
                <BalanceText width={balance?.toString().length * 24} ref={widthRef}>
                    <animated.span>
                        {animatedBalance.to((val) => numberWithCommas(Math.floor(val)))}
                    </animated.span>
                </BalanceText>
            </BalanceWrapper>
            <Link to={'/chests-info'} style={{position: "absolute", right: 16, zIndex: 1}}>
                <svg
                    opacity="0.5"
                    xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                    <path
                        d="M16 0C7.17733 0 0 7.17733 0 16C0 24.8227 7.17733 32 16 32C24.8227 32 32 24.8227 32 16C32 7.17733 24.8227 0 16 0ZM16 26C15.08 26 14.3333 25.2533 14.3333 24.3333C14.3333 23.4133 15.08 22.6667 16 22.6667C16.92 22.6667 17.6667 23.4133 17.6667 24.3333C17.6667 25.2533 16.92 26 16 26ZM19.584 16.4027C17.6587 18.0347 17.3333 18.6253 17.3333 19.3333C17.3333 20.0707 16.7373 20.6667 16 20.6667C15.2627 20.6667 14.6667 20.0707 14.6667 19.3333C14.6667 17.4213 15.7427 16.1613 17.86 14.368C18.456 13.8613 20.3187 12.0747 19.2253 10.3067C18.516 9.15867 15.8693 8.99467 13.676 9.96533C13 10.2627 12.2147 9.95733 11.9173 9.284C11.62 8.61067 11.9253 7.82267 12.5987 7.52533C15.7053 6.152 19.8827 6.29333 21.4947 8.90533C22.9653 11.284 22.2333 14.156 19.584 16.4013V16.4027Z"
                        fill="white"/>
                </svg>
            </Link>
        </div>
    );
};

const Coin = styled.img`
    width: 38px;
    height: 38px;


    @media (max-height: 800px) {
        width: 38px;
        height: 38px;
    }
`;

const BalanceText = styled.div<{ width?: number }>`
    font-size: 32px;
    color: white;
    font-weight: bold;
    line-height: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
     white-space: nowrap; /* Prevent text wrapping */
    width: ${({width}) => width ? `${width}px` : "auto"};
    @media (max-height: 800px) {
        font-size: 32px;
    }
`;

const BalanceWrapper = styled.div`
    display: flex;
    justify-content: space-between; // Align text and coin to the start
    align-items: center;
    position: relative; // Position relative to contain the absolute coin
    width: max-content; // Set width to max-content plus 50px
    padding-inline: 2px;
`;
