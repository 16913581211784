import {authHandler} from "./requests/user-info";
import {retrieveLaunchParams} from "@telegram-apps/sdk";

export const ACCESS_TOKEN = "access_token";


export const TESTROW = 'user=%BNaNd%6%3A921444052%6C%6first_name%6%3A%6Ivan%6%2C%6last_name%6%3A%6%22%6C%6username%6%3A%6vanekchekarmum%6%2C%6language_code%6%3A%6ru%6%2C%6is_premium%6%3Atrue%6C%6allows_write_to_pm%6%3Atrue%6D&chat_instance=-7848722800587611863&chat_type=private&auth_date=1720183490&hash=16f997672c2e434583914b08e5c81635812af1811f2ea55e5495ba5cabc1165a'

interface ApiUrls {
    auth: string;
    dashboard: string;
    promo: string;
}


export interface ApiError {
    code: number;
    message: string;
}

interface FetchOptions<T> extends RequestInit {
    data?: T;
    headers?: HeadersInit;
}


interface PostParams<T> {
    input: RequestInfo;
    data?: T;
    init?: FetchOptions<T>;
    serviceName?: keyof ApiUrls;
}

export async function post<T>(
    {input, data, init}: PostParams<T>
): Promise<Response> {
    const {headers, ...restInit} = init ?? {};

    return fetcher(input, {
        method: "POST",
        headers: {
            ...(headers || {}),
        },
        ...restInit,
        body: data instanceof FormData ? data : JSON.stringify(data),
    });
}


export const fetcher = async <T>(
    input: RequestInfo,
    options?: FetchOptions<T>,
): Promise<Response> => {

    const apiURL = `https://api.diggergame.app`

    let requestOptions = {
        ...options,
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.getItem('token')}` || "",
            ...options?.headers,
        },
    };

    if (options?.data) {
        requestOptions.body = JSON.stringify(options.data);
    }

    try {
        const response = await fetch(`${apiURL}${input}`, requestOptions);
        if (response.status === 401 || response.status === 422) {

            localStorage.removeItem('token');

            const resp = await authHandler(retrieveLaunchParams().initDataRaw as string);
            localStorage.setItem('token', resp.result.auth.token);
            return fetcher(input, options);


        } else if (response.status >= 400) {
            const result = (await response.json()) as ApiError;

            throw new Error(result.message);
        }
        return response;
    } catch (error) {
        throw error;
    }
};

interface FetchSWR<T> {
    input: RequestInfo;
    init?: FetchOptions<T>;
    serviceName?: keyof ApiUrls;
}

export async function fetchSWR<T>({
                                      input,
                                      init,
                                  }: FetchSWR<T>): Promise<T> {
    const requestOptions = {
        ...init,
        headers: {
            ...init?.headers,
            "Content-Type": "application/json"
        },
    };
    const response = await fetcher(input);
    return await response.json();
}


