import {fetcher, post} from "../apiClient";
import {Account, TonProofItemReplySuccess} from "@tonconnect/ui-react";


export async function checkProof(proof: TonProofItemReplySuccess['proof'], account: Account) {
    const response = await post({
        input: '/api/ton/proof/check',
        init: {
            headers: {
                'Content-Type': 'application/json',
            }
        },
        data: {
            address: account.address,
            network: account.chain,
            proof: {
                ...proof,
                state_init: account.walletStateInit
            }
        }
    });
    return (await response.json()) as any;
}

export async function generatePayload( ) {
    const response = await fetcher('/api/ton/proof/payload', {
         headers: {
            'Content-Type': 'application/json',
        },
    });
    return (await response.json()) as Response<{
        payload: string; }>;
}

interface Response<T> {
    result: T;
}