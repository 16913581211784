import {Info as TopInfo} from "../components/info";
import {Balance} from "../components/balance";
import React, {useEffect, useMemo, useRef, useState} from "react";
import styled from "styled-components";
import {ChestList} from "../components/chest-list";
import {Header} from "../components/header";
import {useChestList} from "../api/requests/chest";
import {useUserInfo} from "../api/requests/user-info";
import {Loader} from "../components/loader";
import {useChestStore} from "../store/chest-store";
import {Menu} from "../components/menu";
import {BagList} from "../components/bag-list";
import {useBagList} from "../api/requests/bag";
import {useSendClicksOnEveryPage} from "../hooks/useSendClicksOnEveryPage";
import {useMeStore} from "../store/me-store";
import {InfoBg} from "../components/icons/info";
import {Info} from "../components/info";
import {Bag as IBag, ChestResult} from "../api/requests/model";
import {buildStyles, CircularProgressbar} from "react-circular-progressbar";

import {useNavigate} from "react-router-dom";
import {MenuSwitch} from "../components/menu-switch";
import {ItemInfoModal} from "../components/item-info-modal";
import {useCooldownStore} from "../store/use-colddown-store";
import {getChest} from "../utils/get-chest";


const calculateAligmentByPosition = (position: { top: number; left: number }) => {
    if (position.left < 100) {
        return 'left'
    } else if (position.left > 200) {
        return 'right'
    } else {
        return 'center'
    }

}

export const getTextByType = (type: string) => {
    switch (type) {
        case 'tap_to_coin':
            return 'Увеличение прибыли от тапа'
        case 'rare_chest_chance':
            return 'Увеличение шанса получения более редкого особого сундука из канала'
        case 'reward_item_chance':
            return 'Увеличение шанса получения предмета из сундука'
        case 'chest_coin_bonus':
            return 'Увеличенное количество монет из открываемых сундуков'
        case 'tap_to_coin_x2_chance':
            return 'Добавление шанса при тапе получить х2 прибыль за тап'
        case 'unlock_chest_discount':
            return 'Скидка на стоимость разблокировки особого сундука'
        case 'open_chest_speed':
            return 'Увеличение скорости открытия сундука'
        default:
            return ''
    }

}

export const Bag = () => {
    const [activeTab, setActiveTab] = useState({label: 'Сундуки', value: 'chests'})
    const {setStartTime, setColdDown, getRemainingTime} = useCooldownStore();

    const {data, isLoading: isLoading1} = useBagList();
    const {setActiveChest, setChests, chests, activeChestId} = useChestStore()
    const {user} = useMeStore()
    const [selectedItem, setSelectedItem] = useState<IBag | null>(null);
    const [itemPosition, setItemPosition] = useState<{ top: number; left: number } | null>(null);

    const containerRef = useRef<HTMLDivElement>(null);
    const infoRef = useRef<HTMLDivElement>(null);

    const handleSelect = (item: IBag, element: HTMLDivElement | null) => {
        setSelectedItem((prevSelectedItem) =>
            prevSelectedItem === item ? null : item
        );

        if (element && containerRef.current) {
            const containerRect = containerRef.current.getBoundingClientRect();
            const elementRect = element.getBoundingClientRect();
            setItemPosition({
                top: elementRect.top - containerRect.top,
                left: elementRect.left - containerRect.left,
            });

        }
    };
    useEffect(() => {
        const handleAnyClick = () => {
            setSelectedItem(null);
        };

        document.addEventListener("mousedown", handleAnyClick);
        document.addEventListener("touchstart", handleAnyClick);

        return () => {
            document.removeEventListener("mousedown", handleAnyClick);
            document.removeEventListener("touchstart", handleAnyClick);
        };
    }, []);


    useSendClicksOnEveryPage()


    const navigate = useNavigate();

    // if (isLoading1 || !user) {
    //     return <Loader/>
    // }


    return (
        <Container


            ref={containerRef}>
            {/*{activeItem &&*/}
            {/*    <Info*/}
            {/*        info={`${getTextByType(activeItem.item?.modifier?.name || '')} + ${activeItem?.item?.modifier.name != 'tap_to_coin' ? ` ${Math.round(activeItem.item?.modifier?.value * 100)} % ` : activeItem.item?.modifier?.value}`}*/}
            {/*    />*/}

            {/*/!*}*!/*/}
            <Header user={user || null}/>
            <Title>Склад</Title>
            <FixedBottomInfo>

                <Wrapper>
                    <MenuSwitch
                        activeTab={activeTab.value}
                        onChange={(tab) => setActiveTab({label: tab, value: tab})}
                        tabs={[{label: 'Сундуки', value: 'chests'}, {
                            label: 'Предметы',
                            value: 'items'
                        }]}/>

                    {activeTab.value === 'items' &&
                        <BagList
                            selected={selectedItem}
                            onSelect={(item, element) => handleSelect(item, element)}
                            data={data?.result || []}
                        />
                    }
                    {activeTab.value === 'chests' &&

                        <ChestList
                            selectedChestId={activeChestId}
                            onSelect={(e) => {
                                setActiveChest(e)
                                // if(chests?.find(chest => chest.uid === e)){
                                //     if(chests?.find(chest => chest.uid === e)?.cooldown_sec){
                                //         const now = Date.now();
                                //         setStartTime(now);
                                //         setColdDown(chests?.find(chest => chest.uid === e)?.cooldown_sec || 0);
                                //     }
                                // }




                                navigate(`/`)
                            }}
                            data={chests || []}
                        />
                    }


                </Wrapper>

            </FixedBottomInfo>
            {selectedItem && itemPosition && (
                <InfoWrapper
                    ref={infoRef}
                    style={{top: itemPosition.top - 65}}
                    >
                    <ItemInfoModal
                        align={calculateAligmentByPosition(itemPosition)}
                         content={`${getTextByType(selectedItem.item?.modifier?.name || '')} + ${selectedItem?.item?.modifier.name != 'tap_to_coin' ? ` ${Math.round(selectedItem.item?.modifier?.value * 100)} % ` : selectedItem.item?.modifier?.value}`}

                    />
                </InfoWrapper>
            )}
            <Menu isAbsolute/>
        </Container>
    );
}




const InfoWrapper = styled.div`
    position: absolute;
    z-index: 10;
    width: 90%;
    left: 50%;
    transform: translateX(-50%);
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 8px 10px 10px 10px;
    height: 100%;
    overflow: hidden;
    width: 100%;
    box-sizing: border-box;
    align-items: center;
    gap: 16px;
`


const FixedBottomInfo = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    height: 80%;
`

const Container = styled.div<{ isBlur?: boolean }>`
    display: flex;
    height: 100vh;
    scrollbar-width: none;
    width: 100%;
    background-image: url('/bg/main-bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    position: relative;
`;


const Title = styled.div`
    color: #FFF;
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px; /* 125% */
`


const Chest = ({chest}: { chest: ChestResult }) => {

    const chestComponent = useMemo(() => getChest({
        id: chest?.chest.id || 0,
        isOpen:  false,
        style: {width: '250px', height: '250px', position: 'absolute', top: '27px', left: '33px', zIndex: 4}
    }), [ chest?.chest]);


    return <Outline>
        <svg style={{height: 0, width: 0}}>
            <defs>
                <linearGradient id="gradientColors" gradientTransform="rotate(90)">
                    <stop offset="0%" stopColor="#E8514A"/>
                    <stop offset="100%" stopColor="#FFC25F"/>
                </linearGradient>
            </defs>
        </svg>
        <CircularProgressbar
            value={chest?.current_tap_cnt / chest?.open_tap_cnt * 100}
            styles={buildStyles({
                    pathColor: 'url(#gradientColors)', // Apply gradient to the progress line
                    textColor: '#f88',
                    backgroundColor: '#2c334f',
                    trailColor: '#2c334f',

                },
            )}
        />
        <svg
            style={{
                position: 'absolute',
                top: '25px',
                left: '25px',
                width: '273px',
                height: '273px',
            }}

            xmlns="http://www.w3.org/2000/svg" width="224" height="224" viewBox="0 0 224 224" fill="none">
            <circle cx="112" cy="112" r="109.583" stroke="#272647" stroke-width="4"/>
        </svg>
        <>

            {chestComponent}
            <Img
                src={chest?.chest?.art_url}
                alt={'chest'}
            />
        </>

    </Outline>
}


const Img = styled.img`
    width: 250px;
    height: 250px;
    position: absolute;
    top: 27px;
    left: 33px;
    z-index: 4;
    touch-action: none;
    user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;

    -webkit-touch-callout: none;
`;


const Outline = styled.div<{ isClicked?: boolean }>`
    width: 320px;
    height: 320px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #A7C3F0;
    background: radial-gradient(50% 50% at 50% 50%, #6371B1 0%, #2A304B 100%);
    position: relative;


`;

