export function random(low: number, high: number) {
    if (low > high) {
        throw new Error()
    }

    return Math.floor(Math.random() * (high - low + 1)) + low;
}

export class AnimatedTap {
    private pool: HTMLDivElement[] = [];
    private $root: HTMLElement;

    constructor(root: HTMLElement | null) {
        if (!root) throw new Error("Root element not found");
        this.$root = root;
    }

    public async animate(e: PointerEvent, tapToCoin: number, isX2: boolean, duration: number = 1000) {
        const div = this.getFreeDivOrCreate();

        const rect = this.$root.getBoundingClientRect();
        const relX = e.clientX - rect.left + random(-20, 20);
        const relYTop = e.clientY - rect.top;
        const relYBottom = rect.height - relYTop;

        div.style.left = `${relX}px`;
        div.style.bottom = `${relYBottom}px`;
        div.style.display = 'block';

        div.textContent = `+${tapToCoin}`;
        div.style.color = '#fff';
        div.style.fontSize = '2.4rem';
        div.style.transform = 'scale(1)';
        div.style.userSelect = "none";
        div.style.zIndex = '1111';
        div.style.fontWeight = 'bold';
        div.style.fontStyle = 'italic';

        const animation = div.animate([
            {transform: 'scale(1)', opacity: 1, bottom: `${relYBottom}px`},
            {transform:  'scale(1)', opacity: 0, bottom: '100%'}
        ], {
            duration: duration,
            easing: 'ease-out',
            fill: 'forwards',
        });

        return new Promise<void>(resolve => {
            animation.onfinish = () => {
                div.style.display = 'none';
                this.pool.push(div);
                resolve();
            }
        });
    }

    private createDiv() {
        const div = document.createElement('div');
        div.style.position = 'absolute';
        div.style.display = 'none';
        this.$root.appendChild(div);
        return div;
    }

    private getFreeDivOrCreate() {
        if (this.pool.length > 0) {
            return this.pool.pop()!;
        }
        return this.createDiv();
    }


}


export const TapAnimation = new AnimatedTap(document.getElementById("animated-tap"));
