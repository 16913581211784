import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {ChestResult} from "../api/requests/model";
import {ClickPosition} from "../App";
import {ColdDown} from "./cold-down";
import {MainChestRef} from "./effects/main-chest-ref";
import {useFinger} from "../hooks/use-finger";
import {IS_VIBRATION} from "../constant";

interface Props {
    total: number;
    tapCount: number;
    chestCount: number;
    onTouchStart?: (e: any) => void;
    isChestOpen: boolean;
    chest: ChestResult | null;
    coldDown?: number;
    mutate: () => Promise<any>;
}

export const MainChest = (props: Props) => {

    const {total, chestCount, isChestOpen, chest,} = props;
    const [isVibration, setIsVibration] = useState(localStorage.getItem(IS_VIBRATION) === 'true');


    useEffect(() => {
        if (!localStorage.getItem(IS_VIBRATION)) {
            localStorage.setItem(IS_VIBRATION, 'true');
            setIsVibration(true);
        }
    }, [localStorage.getItem(IS_VIBRATION)]);

    const {tapAreaRef, tapCircleRef} = useFinger({
        onTouchStart: props.onTouchStart,
        tapToCoin: props.tapCount,
        isDisabled: (chest?.status == 'locked' || isChestOpen),
        isVibration

    });


    if (props.coldDown && props.coldDown > 0) {
        return <ColdDown scale={0.8} mutate={props.mutate} coldDown={props.coldDown}/>;
    }
    const handleContextMenu = (e: React.MouseEvent) => {
        e.preventDefault();
    };


    if (!chest) {
        return null;
    }


    return (
        <Relative
            onContextMenu={handleContextMenu}
            ref={tapAreaRef}
        >
            <MainChestRef
                total={total}
                chestCount={chestCount}
                ref={tapCircleRef}
                chest={chest}
                isChestOpen={isChestOpen}
            />
        </Relative>
    );
};

const Relative = styled.div`
    position: relative;
    @media (max-height: 800px) {
        scale: 0.8;
    }
`;



