
import { useEffect, useState } from 'react';
export const useWidth = () => {
    const [width, setWidth] = useState(window.innerWidth * 0.9);
    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth * 0.9);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return width;

}