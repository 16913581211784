import {create} from 'zustand';

type State = {
    startTime: number | null;
    coldDown: number;
    setStartTime: (time: number) => void;
    setColdDown: (time: number) => void;
    getRemainingTime: () => number;
};

export const useCooldownStore = create<State>((set, getState) => ({
    startTime: null,
    coldDown: 0,
    setStartTime: (time) => set({ startTime: time }),
    setColdDown: (time) => set({ coldDown: time }),
    getRemainingTime: () => {
        const { startTime, coldDown } = getState();
        if (!startTime) return 0;
        const elapsedTime = Math.floor((Date.now() - startTime) / 1000);
        return Math.max(coldDown - elapsedTime, 0);
    }
}));

