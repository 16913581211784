import React from "react";

export const Ton = (props: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <g clip-path="url(#clip0_407_223785)">
                <path
                    d="M23.6401 14.9061C23.0687 17.2101 21.8267 19.2928 20.0713 20.8907C18.3159 22.4887 16.126 23.53 13.7786 23.8829C11.4312 24.2358 9.03188 23.8845 6.88421 22.8735C4.73654 21.8624 2.93704 20.2369 1.71343 18.2028C0.489822 16.1687 -0.102916 13.8174 0.0102211 11.4463C0.123358 9.07522 0.937286 6.79099 2.34901 4.88263C3.76074 2.97428 5.70682 1.52757 7.94101 0.725559C10.1752 -0.0764466 12.5971 -0.197703 14.9002 0.377134C17.9846 1.14696 20.6373 3.10938 22.276 5.83346C23.9147 8.55754 24.4053 11.8206 23.6401 14.9061Z"
                    fill="#0089CD"/>
                <g opacity="0.24">
                    <path
                        d="M11.9981 23.9984C11.0192 23.9915 10.0447 23.8656 9.0961 23.6234C6.01685 22.8494 3.36994 20.8865 1.73526 18.1646C0.10058 15.4427 -0.388574 12.1838 0.374964 9.10197C0.949624 6.80034 2.19369 4.72072 3.94994 3.12595C5.70618 1.53117 7.89577 0.492807 10.242 0.142099C12.5882 -0.208609 14.9857 0.144075 17.1316 1.15558C19.2774 2.16708 21.0752 3.792 22.2978 5.82498C23.3913 7.6465 23.9815 9.72571 24.008 11.8501C24.0346 13.9744 23.4967 16.0678 22.4492 17.9161C21.4016 19.7644 19.8821 21.3015 18.0459 22.3701C16.2097 23.4387 14.1227 24.0006 11.9981 23.9984ZM11.9981 2.25179C9.82367 2.25347 7.71218 2.98213 5.99945 4.32187C4.28672 5.66162 3.07109 7.53554 2.54587 9.64563C2.18915 10.9026 2.08876 12.2185 2.25068 13.5151C2.41259 14.8117 2.83351 16.0625 3.48841 17.1931C4.14332 18.3238 5.01884 19.3113 6.06294 20.0969C7.10705 20.8824 8.29843 21.4501 9.56628 21.7661C10.8341 22.0822 12.1526 22.1401 13.4433 21.9364C14.7339 21.7328 15.9705 21.2718 17.0795 20.5808C18.1884 19.8898 19.1472 18.9829 19.8987 17.914C20.6502 16.8451 21.1792 15.636 21.4542 14.3586C22.0789 11.8508 21.6821 9.19751 20.3512 6.98207C19.0203 4.76663 16.864 3.17039 14.3565 2.54424C13.5851 2.35208 12.7932 2.25388 11.9981 2.25179Z"
                        fill="white"/>
                </g>
                <g opacity="0.3">
                    <path
                        d="M23.2802 7.92453L0.153767 13.9236C-0.323761 10.9735 0.31688 7.95133 1.9503 5.4487C3.58371 2.94606 6.09231 1.1431 8.98509 0.392704C11.8779 -0.357692 14.9466 -0.0014975 17.5905 1.39157C20.2345 2.78463 22.2634 5.11428 23.2802 7.92453Z"
                        fill="white"/>
                </g>
                <path
                    d="M21.4542 14.3587C20.9881 16.2299 19.9775 17.9207 18.5503 19.2175C17.1232 20.5142 15.3435 21.3585 13.4364 21.6437C11.5293 21.9288 9.58042 21.642 7.83635 20.8194C6.09229 19.9969 4.63135 18.6756 3.63832 17.0226C2.64529 15.3697 2.16478 13.4593 2.25757 11.5333C2.35037 9.60719 3.0123 7.75191 4.15963 6.20208C5.30696 4.65224 6.88816 3.47749 8.70322 2.8264C10.5183 2.17532 12.4857 2.07716 14.3565 2.54433C16.864 3.17047 19.0202 4.76672 20.3512 6.98216C21.6821 9.19759 22.0789 11.8509 21.4542 14.3587Z"
                    fill="#0089CD"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M17.3823 8.1347C17.2409 7.86874 17.0136 7.65857 16.7374 7.53854C16.4877 7.41472 16.2136 7.34812 15.935 7.34357H8.13247C7.83822 7.32421 7.54478 7.39202 7.28886 7.53854C7.12645 7.59133 6.97634 7.67631 6.8475 7.7884C6.71866 7.90049 6.61373 8.03739 6.53897 8.19094C6.36957 8.47526 6.29597 8.80648 6.32901 9.13579C6.35223 9.44047 6.46001 9.73263 6.64021 9.97941L11.4844 18.4268C11.5449 18.5146 11.6254 18.5865 11.7193 18.6368C11.8133 18.687 11.9179 18.714 12.0244 18.7155C12.1342 18.7246 12.2443 18.7023 12.3418 18.6512C12.4394 18.6 12.5205 18.5222 12.5755 18.4268L17.4498 9.97941C17.6186 9.71202 17.7011 9.39916 17.686 9.0833C17.6656 8.74668 17.5612 8.42054 17.3823 8.1347ZM11.4095 15.791L7.68629 9.28952L7.54007 9.00081C7.52451 8.91152 7.54182 8.81961 7.58881 8.7421C7.62757 8.65402 7.69872 8.58421 7.78753 8.54713H11.4095V15.791ZM16.3812 9.04205C16.4037 9.14203 16.4037 9.24578 16.3812 9.34576L12.5755 15.821V8.58088H15.8825C16.0001 8.56818 16.119 8.58627 16.2275 8.63337L16.28 8.68586C16.3324 8.68586 16.3324 8.77959 16.3812 8.77959C16.4075 8.85998 16.4075 8.94667 16.3812 9.02706V9.04205Z"
                      fill="white"/>
            </g>
            <defs>
                <clipPath id="clip0_407_223785">
                    <rect width="24" height="23.9963" fill="white" transform="translate(0 0.00195312)"/>
                </clipPath>
            </defs>
        </svg>
    )
}