import {create} from 'zustand';

type ClickState = {
    clicks: number;
    setClicks: (clicks:(prev: number) => number) => void;
};

export const useClickStore = create<ClickState>((set) => ({
    clicks: 0,
    setClicks: (clicks) => set((state) => ({clicks: clicks(state.clicks)})),
}));