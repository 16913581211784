import styled, {keyframes} from "styled-components";


interface Props {
    content: string;
    width?: number;
    align?: 'left' | 'center' | 'right';
}

export const ItemInfoModal = (props: Props) => {

    return (
        <AnimatedRelative>
            <Container >
                <Content>
                    {props.content}
                </Content>
            </Container>
            <svg
                style={{
                    position: 'absolute',
                    zIndex: 100,
                    left: props.align === 'left' ? '10%' : props.align === 'center' ? '48%' : '90%',
                    bottom: -10

                }}
                xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14" fill="none">
                <path
                    d="M7.01598 11.5904C8.01666 12.8956 9.98334 12.8956 10.984 11.5904L16.4038 4.52108C17.6647 2.87647 16.4921 0.5 14.4198 0.5H3.58021C1.50789 0.5 0.335331 2.87647 1.5962 4.52108L7.01598 11.5904Z"
                    fill="#0B1E2C" stroke="url(#paint0_linear_399_246794)"/>
                <defs>
                    <linearGradient id="paint0_linear_399_246794" x1="9" y1="15" x2="9" y2="0"
                                    gradientUnits="userSpaceOnUse">
                        <stop offset="0.798905" stop-color="#1F71AD"/>
                        <stop offset="0.820804" stop-color="#0B1E2C"/>
                    </linearGradient>
                </defs>
            </svg>
        </AnimatedRelative>
    )

}


const Absolute = styled.div`
    position: absolute;
    z-index: 1000000000000000000000000;
    left: 50%;
    top: -27px;
    transform: translateX(-50%);
`

const Relative = styled.div`
    position: relative;
    width: 100%;
    box-sizing: border-box;
`


const Container = styled.div `
    box-sizing: border-box;

    display: flex;
    width: 100%;
    padding: 16px 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    z-index: 100;
    border-radius: 16px;
    border: 1px solid #1F71AD;
    background: #0B1E2C;
    box-shadow: 0px 16px 24px 0px rgba(11, 30, 44, 0.15);
`


const Content = styled.div`
    color: #FFF;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
`

const fadeIn = keyframes`
    from {
        opacity: 0;
        transform: scale(0.8);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
`;


const AnimatedRelative = styled(Relative)`
    animation: ${fadeIn} 0.3s ease-in-out;
`;

