export const ShineBG = (props: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg" width="454" height="454" viewBox="0 0 454 454" fill="none">
            <ellipse cx="227" cy="223" rx="200" ry="194" fill="url(#paint0_radial_16_56866)"/>
            <path d="M175.945 56L226.448 226.399L275.9 56H175.945Z" fill="url(#paint1_linear_16_56866)"/>
            <path d="M276.853 396.798L226.349 226.399L176.898 396.798L276.853 396.798Z"
                  fill="url(#paint2_linear_16_56866)"/>
            <path d="M396.799 175.946L226.4 226.449L396.799 275.901L396.799 175.946Z"
                  fill="url(#paint3_linear_16_56866)"/>
            <path d="M56 276.852L226.399 226.349L56 176.898L56 276.852Z" fill="url(#paint4_linear_16_56866)"/>
            <path d="M70.2786 141.91L226.48 226.689L140.957 71.2314L70.2786 141.91Z"
                  fill="url(#paint5_linear_16_56866)"/>
            <path d="M310.715 70.2793L225.936 226.481L381.394 140.958L310.715 70.2793Z"
                  fill="url(#paint6_linear_16_56866)"/>
            <path d="M381.395 310.716L225.193 225.936L310.716 381.394L381.395 310.716Z"
                  fill="url(#paint7_linear_16_56866)"/>
            <path d="M140.958 382.346L225.737 226.145L70.2795 311.668L140.958 382.346Z"
                  fill="url(#paint8_linear_16_56866)"/>
            <defs>
                <radialGradient id="paint0_radial_16_56866" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                gradientTransform="translate(227 223) rotate(90) scale(194 200)">
                    <stop offset="0.38" stop-color="#FFDB7D"/>
                    <stop offset="1" stop-color="#806200" stop-opacity="0"/>
                </radialGradient>
                <linearGradient id="paint1_linear_16_56866" x1="225.922" y1="56" x2="225.922" y2="226.399"
                                gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FDE7A6" stop-opacity="0"/>
                    <stop offset="1" stop-color="#FCE7A6"/>
                </linearGradient>
                <linearGradient id="paint2_linear_16_56866" x1="226.875" y1="396.798" x2="226.875" y2="226.399"
                                gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FDE7A6" stop-opacity="0"/>
                    <stop offset="1" stop-color="#FCE7A6"/>
                </linearGradient>
                <linearGradient id="paint3_linear_16_56866" x1="396.799" y1="225.923" x2="226.4" y2="225.923"
                                gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FDE7A6" stop-opacity="0"/>
                    <stop offset="1" stop-color="#FCE7A6"/>
                </linearGradient>
                <linearGradient id="paint4_linear_16_56866" x1="56" y1="226.875" x2="226.399" y2="226.875"
                                gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FDE7A6" stop-opacity="0"/>
                    <stop offset="1" stop-color="#FCE7A6"/>
                </linearGradient>
                <linearGradient id="paint5_linear_16_56866" x1="105.618" y1="106.571" x2="226.108" y2="227.061"
                                gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FDE7A6" stop-opacity="0"/>
                    <stop offset="1" stop-color="#FCE7A6"/>
                </linearGradient>
                <linearGradient id="paint6_linear_16_56866" x1="346.055" y1="105.619" x2="225.564" y2="226.109"
                                gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FDE7A6" stop-opacity="0"/>
                    <stop offset="1" stop-color="#FCE7A6"/>
                </linearGradient>
                <linearGradient id="paint7_linear_16_56866" x1="346.055" y1="346.055" x2="225.565" y2="225.564"
                                gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FDE7A6" stop-opacity="0"/>
                    <stop offset="1" stop-color="#FCE7A6"/>
                </linearGradient>
                <linearGradient id="paint8_linear_16_56866" x1="105.619" y1="347.007" x2="226.109" y2="226.517"
                                gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FDE7A6" stop-opacity="0"/>
                    <stop offset="1" stop-color="#FCE7A6"/>
                </linearGradient>
            </defs>
        </svg>
    )
}