 import styled from "styled-components";

interface DeckItemProps {
    title: string;
    value: string;
    color: string | undefined;
}

export const InfoItem = (props: DeckItemProps) => {

    return (
        <Container>
            <Row>
                <Title>{props.title}</Title>
                <Value
                >
                    <ValueText
                        size={props.value.length > 3 ? 14 : undefined}
                        color={props.color}>
                        {props.value}
                    </ValueText>
                    <img
                        src="/images/icon-coin.png"
                        style={{
                            marginLeft: 5,
                            width: 38,
                            height: 38,
                        }}
                    />
                </Value>
            </Row>
        </Container>
    )
}


const Container = styled.div`
    display: flex;
    padding: 8px 12px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    border-radius: 8px;
    border: 1px solid #1C3246;
    background: #112232;
`


const Title = styled.div`
    color: #FFF;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
`

const Value = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;

`


const ValueText = styled.div<{ color: string | undefined, size: number | undefined }>`
    font-size: ${props => props.size || 20}px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; /* 140% */
    color: #fcce05;
 
`

const Row = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
`