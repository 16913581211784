import styled from "styled-components";
import {ItemBox} from "./icons/item-box";

import React from "react";
import {useWidth} from "../hooks/useWidth";
import {ChestResult} from "../api/requests/model";
import {buildStyles, CircularProgressbar} from "react-circular-progressbar";
import {getGradientsById} from "../utils/get-gradients-by-id";
import 'react-circular-progressbar/dist/styles.css';

interface Props {
    data: ChestResult | undefined;
    onClick?: () => void;
    isSelected?: boolean;

}

export const ListItem = (props: Props) => {

    const WIDTH = useWidth()

    return (
        <Relative onClick={props.onClick}
        >
            {props.isSelected &&
                <Border
                    style={{
                        width: WIDTH / 3,
                        height: WIDTH / 3,
                        position: 'absolute',

                    }}/>
            }
            <ItemBox
                style={{
                    width: WIDTH / 3,
                    height: WIDTH / 3,
                    borderWidth: '20px',
                }}
            />
            {props?.data &&
                <div style={{
                    zIndex: 1,
                    position: "absolute",
                    top: '7%',
                    left: '6%',
                }}>
                    <ChestItem
                        data={props.data}
                        width={WIDTH / 3.5}
                    />
                </div>
            }

        </Relative>
    )

}


const Relative = styled.div`
    position: relative;
`


interface ChestProps {
    data: ChestResult | null
    width: number

}

const ChestItem = (props: ChestProps) => {
    const width = props?.width

    return (
        <Relative
        >

            <Outline
                width={width}
            >
                <svg style={{height: 0, width: 0}}>
                    <defs>
                        <linearGradient id="gradientColor" gradientTransform="rotate(90)">
                            <stop offset="0%" stopColor={getGradientsById(Number(props?.data?.chest?.id))[0]}/>
                            <stop offset="100%" stopColor={getGradientsById(Number(props?.data?.chest.id))[1]}/>
                        </linearGradient>
                    </defs>
                </svg>

                <CircularProgressbar
                    value={(props?.data?.current_tap_cnt || 0) / (props?.data?.open_tap_cnt || 0) * 100}
                    styles={buildStyles({
                            pathColor: 'url(#gradientColor)',
                            textColor: '#f88',
                            backgroundColor: '#2c334f',
                            trailColor: '#2c334f',

                        },
                    )}
                />

                <Img
                    width={width * 0.85}
                    src={props?.data?.chest?.art_url}
                    alt={'chest'}/>


            </Outline>

        </Relative>
    )
}


const Img = styled.img<{ width: number }>`
    width: ${(props) => props.width * 0.9}px;
    height: ${(props) => props.width * 0.9}px;
    position: absolute;
    top: 12px;
    left: 12px;
    z-index: 3;
`;


const Outline = styled.div<{ width: number }>`
    width: ${(props) => props.width}px;
    height: ${(props) => props.width}px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #1C0E3A;
    border: 1px solid #A7C3F0;
`;


const Border = (props: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg" width="105" height="105" viewBox="0 0 105 105" fill="none">
            <path
                d="M101.891 93.7748C101.53 97.6413 97.967 101.2 94.1151 101.553C66.2815 103.698 39.053 103.698 11.2291 101.553C7.36257 101.191 3.80361 97.6283 3.45121 93.7763C1.30626 65.9428 1.30622 38.7144 3.45109 10.8906C3.81289 7.02403 7.37559 3.46506 11.2276 3.11265C39.0611 0.967698 66.2895 0.967662 94.1133 3.11252C97.9797 3.4743 101.539 7.03688 101.891 10.8888C104.036 38.7224 104.036 65.9509 101.891 93.7748Z"
                stroke="url(#paint0_linear_214_49407)" stroke-width="3"/>
            <defs>
                <linearGradient id="paint0_linear_214_49407" x1="52.6712" y1="0.00390625" x2="52.6712" y2="104.661"
                                gradientUnits="userSpaceOnUse">
                    <stop stop-color="#DDA35F"/>
                    <stop offset="0.185" stop-color="#F2EDB9"/>
                    <stop offset="1" stop-color="#693C1D"/>
                </linearGradient>
            </defs>
        </svg>
    )
}