import useSWR from "swr";
import {fetchSWR} from "../apiClient";
import {Response, TopUser} from "./model";

export const useTopUsers = (skip?: boolean) => {
    return useSWR(skip ? undefined :
            '/api/leaderboard/users',
        (url: any) => fetchSWR<Response<{
            users: TopUser[]
            me: TopUser
        }>>({input: url}), {
            revalidateOnFocus: false
        }
    );
}
