import {useEffect, useRef} from "react";
import {useIsConnectionRestored, useTonConnectUI, useTonWallet} from "@tonconnect/ui-react";
import {checkProof, generatePayload} from "../api/requests/blockchain";

const payloadTTLMS = 1000 * 60 * 20;

export function useBackendAuth() {
    const isConnectionRestored = useIsConnectionRestored();
    const wallet = useTonWallet();
    const [tonConnectUI] = useTonConnectUI();
    const interval = useRef<ReturnType<typeof setInterval> | undefined>();

    useEffect(() => {

        if (!isConnectionRestored) {
            return;
        }

        clearInterval(interval.current);

        if (!wallet) {
            const refreshPayload = async () => {
                tonConnectUI.setConnectRequestParameters({state: 'loading'});

                const value1 = await generatePayload();
                console.log('Generated payload', value1);
                if (!value1.result.payload) {
                    tonConnectUI.setConnectRequestParameters(null);
                } else {
                    console.log('Generated payload', value1.result.payload);

                    tonConnectUI.setConnectRequestParameters({
                        state: 'ready',
                        value: {
                            tonProof: value1.result.payload as string
                        }
                    });
                }
            }

            refreshPayload().then()
            setInterval(refreshPayload, payloadTTLMS);
            return;
        }

        if (wallet.connectItems?.tonProof && !('error' in wallet.connectItems.tonProof)) {
            checkProof(wallet.connectItems.tonProof.proof, wallet.account).then(result => {
                if (result) {
                    console.log('Proof is valid', wallet);
                } else {
                    alert('Please try another wallet');
                    tonConnectUI.disconnect();
                }
            })
        } else {
            console.log('No proof', wallet);
            tonConnectUI.disconnect();
        }

    }, [wallet, isConnectionRestored])
}