export const Shine = (props: React.SVGProps<SVGSVGElement>) => {
    return(
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg" width="314" height="313" viewBox="0 0 314 313" fill="none">
            <g filter="url(#filter0_f_16_56892)">
                <path d="M63 41L63.9899 54.0101L77 55L63.9899 55.9899L63 69L62.0101 55.9899L49 55L62.0101 54.0101L63 41Z" fill="url(#paint0_radial_16_56892)"/>
            </g>
            <g filter="url(#filter1_f_16_56892)">
                <path d="M292 130L293.485 149.515L313 151L293.485 152.485L292 172L290.515 152.485L271 151L290.515 149.515L292 130Z" fill="url(#paint1_radial_16_56892)"/>
            </g>
            <g filter="url(#filter2_f_16_56892)">
                <path d="M264 235L265.485 254.515L285 256L265.485 257.485L264 277L262.515 257.485L243 256L262.515 254.515L264 235Z" fill="url(#paint2_radial_16_56892)"/>
            </g>
            <g filter="url(#filter3_f_16_56892)">
                <path d="M61 91L62.4849 110.515L82 112L62.4849 113.485L61 133L59.5151 113.485L40 112L59.5151 110.515L61 91Z" fill="url(#paint3_radial_16_56892)"/>
            </g>
            <g filter="url(#filter4_f_16_56892)">
                <path d="M22 153L23.5846 160.415L31 162L23.5846 163.585L22 171L20.4154 163.585L13 162L20.4154 160.415L22 153Z" fill="url(#paint4_radial_16_56892)"/>
            </g>
            <g filter="url(#filter5_f_16_56892)">
                <path d="M249 72L250.264 76.7357L255 78L250.264 79.2643L249 84L247.736 79.2643L243 78L247.736 76.7357L249 72Z" fill="url(#paint5_radial_16_56892)"/>
            </g>
            <g filter="url(#filter6_f_16_56892)">
                <path d="M216 247L217.264 251.736L222 253L217.264 254.264L216 259L214.736 254.264L210 253L214.736 251.736L216 247Z" fill="url(#paint6_radial_16_56892)"/>
            </g>
            <g filter="url(#filter7_f_16_56892)">
                <path d="M74 235L75.2643 239.736L80 241L75.2643 242.264L74 247L72.7357 242.264L68 241L72.7357 239.736L74 235Z" fill="url(#paint7_radial_16_56892)"/>
            </g>
            <g filter="url(#filter8_f_16_56892)">
                <path d="M249 12L250.264 16.7357L255 18L250.264 19.2643L249 24L247.736 19.2643L243 18L247.736 16.7357L249 12Z" fill="url(#paint8_radial_16_56892)"/>
            </g>
            <g filter="url(#filter9_f_16_56892)">
                <path d="M7 69L8.26431 73.7357L13 75L8.26431 76.2643L7 81L5.73569 76.2643L1 75L5.73569 73.7357L7 69Z" fill="url(#paint9_radial_16_56892)"/>
            </g>
            <g filter="url(#filter10_f_16_56892)">
                <path d="M163 1L164.369 10.631L174 12L164.369 13.369L163 23L161.631 13.369L152 12L161.631 10.631L163 1Z" fill="url(#paint10_radial_16_56892)"/>
            </g>
            <g filter="url(#filter11_f_16_56892)">
                <path d="M45 225L46.369 234.631L56 236L46.369 237.369L45 247L43.631 237.369L34 236L43.631 234.631L45 225Z" fill="url(#paint11_radial_16_56892)"/>
            </g>
            <g filter="url(#filter12_f_16_56892)">
                <path d="M174 290L175.369 299.631L185 301L175.369 302.369L174 312L172.631 302.369L163 301L172.631 299.631L174 290Z" fill="url(#paint12_radial_16_56892)"/>
            </g>
            <g filter="url(#filter13_f_16_56892)">
                <path d="M61 278L61.8485 289.151L73 290L61.8485 290.849L61 302L60.1515 290.849L49 290L60.1515 289.151L61 278Z" fill="url(#paint13_radial_16_56892)"/>
            </g>
            <defs>
                <filter id="filter0_f_16_56892" x="48" y="40" width="30" height="30" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                    <feGaussianBlur stdDeviation="0.5" result="effect1_foregroundBlur_16_56892"/>
                </filter>
                <filter id="filter1_f_16_56892" x="270" y="129" width="44" height="44" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                    <feGaussianBlur stdDeviation="0.5" result="effect1_foregroundBlur_16_56892"/>
                </filter>
                <filter id="filter2_f_16_56892" x="242" y="234" width="44" height="44" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                    <feGaussianBlur stdDeviation="0.5" result="effect1_foregroundBlur_16_56892"/>
                </filter>
                <filter id="filter3_f_16_56892" x="39" y="90" width="44" height="44" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                    <feGaussianBlur stdDeviation="0.5" result="effect1_foregroundBlur_16_56892"/>
                </filter>
                <filter id="filter4_f_16_56892" x="12" y="152" width="20" height="20" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                    <feGaussianBlur stdDeviation="0.5" result="effect1_foregroundBlur_16_56892"/>
                </filter>
                <filter id="filter5_f_16_56892" x="242" y="71" width="14" height="14" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                    <feGaussianBlur stdDeviation="0.5" result="effect1_foregroundBlur_16_56892"/>
                </filter>
                <filter id="filter6_f_16_56892" x="209" y="246" width="14" height="14" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                    <feGaussianBlur stdDeviation="0.5" result="effect1_foregroundBlur_16_56892"/>
                </filter>
                <filter id="filter7_f_16_56892" x="67" y="234" width="14" height="14" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                    <feGaussianBlur stdDeviation="0.5" result="effect1_foregroundBlur_16_56892"/>
                </filter>
                <filter id="filter8_f_16_56892" x="242" y="11" width="14" height="14" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                    <feGaussianBlur stdDeviation="0.5" result="effect1_foregroundBlur_16_56892"/>
                </filter>
                <filter id="filter9_f_16_56892" x="0" y="68" width="14" height="14" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                    <feGaussianBlur stdDeviation="0.5" result="effect1_foregroundBlur_16_56892"/>
                </filter>
                <filter id="filter10_f_16_56892" x="151" y="0" width="24" height="24" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                    <feGaussianBlur stdDeviation="0.5" result="effect1_foregroundBlur_16_56892"/>
                </filter>
                <filter id="filter11_f_16_56892" x="33" y="224" width="24" height="24" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                    <feGaussianBlur stdDeviation="0.5" result="effect1_foregroundBlur_16_56892"/>
                </filter>
                <filter id="filter12_f_16_56892" x="162" y="289" width="24" height="24" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                    <feGaussianBlur stdDeviation="0.5" result="effect1_foregroundBlur_16_56892"/>
                </filter>
                <filter id="filter13_f_16_56892" x="48" y="277" width="26" height="26" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                    <feGaussianBlur stdDeviation="0.5" result="effect1_foregroundBlur_16_56892"/>
                </filter>
                <radialGradient id="paint0_radial_16_56892" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(63 55) rotate(90) scale(14)">
                    <stop offset="0.208333" stop-color="#FADF99"/>
                    <stop offset="0.916667" stop-color="#F4CA76"/>
                </radialGradient>
                <radialGradient id="paint1_radial_16_56892" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(292 151) rotate(90) scale(21)">
                    <stop offset="0.208333" stop-color="#FADF99"/>
                    <stop offset="0.916667" stop-color="#F4CA76"/>
                </radialGradient>
                <radialGradient id="paint2_radial_16_56892" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(264 256) rotate(90) scale(21)">
                    <stop offset="0.208333" stop-color="#FADF99"/>
                    <stop offset="0.916667" stop-color="#F4CA76"/>
                </radialGradient>
                <radialGradient id="paint3_radial_16_56892" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(61 112) rotate(90) scale(21)">
                    <stop offset="0.208333" stop-color="#FADF99"/>
                    <stop offset="0.916667" stop-color="#F4CA76"/>
                </radialGradient>
                <radialGradient id="paint4_radial_16_56892" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(22 162) rotate(90) scale(9)">
                    <stop offset="0.208333" stop-color="#FADF99"/>
                    <stop offset="0.916667" stop-color="#F4CA76"/>
                </radialGradient>
                <radialGradient id="paint5_radial_16_56892" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(249 78) rotate(90) scale(6)">
                    <stop offset="0.208333" stop-color="#FADF99"/>
                    <stop offset="0.916667" stop-color="#F4CA76"/>
                </radialGradient>
                <radialGradient id="paint6_radial_16_56892" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(216 253) rotate(90) scale(6)">
                    <stop offset="0.208333" stop-color="#FADF99"/>
                    <stop offset="0.916667" stop-color="#F4CA76"/>
                </radialGradient>
                <radialGradient id="paint7_radial_16_56892" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(74 241) rotate(90) scale(6)">
                    <stop offset="0.208333" stop-color="#FADF99"/>
                    <stop offset="0.916667" stop-color="#F4CA76"/>
                </radialGradient>
                <radialGradient id="paint8_radial_16_56892" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(249 18) rotate(90) scale(6)">
                    <stop offset="0.208333" stop-color="#FADF99"/>
                    <stop offset="0.916667" stop-color="#F4CA76"/>
                </radialGradient>
                <radialGradient id="paint9_radial_16_56892" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(7 75) rotate(90) scale(6)">
                    <stop offset="0.208333" stop-color="#FADF99"/>
                    <stop offset="0.916667" stop-color="#F4CA76"/>
                </radialGradient>
                <radialGradient id="paint10_radial_16_56892" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(163 12) rotate(90) scale(11)">
                    <stop offset="0.208333" stop-color="#FADF99"/>
                    <stop offset="0.916667" stop-color="#F4CA76"/>
                </radialGradient>
                <radialGradient id="paint11_radial_16_56892" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(45 236) rotate(90) scale(11)">
                    <stop offset="0.208333" stop-color="#FADF99"/>
                    <stop offset="0.916667" stop-color="#F4CA76"/>
                </radialGradient>
                <radialGradient id="paint12_radial_16_56892" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(174 301) rotate(90) scale(11)">
                    <stop offset="0.208333" stop-color="#FADF99"/>
                    <stop offset="0.916667" stop-color="#F4CA76"/>
                </radialGradient>
                <radialGradient id="paint13_radial_16_56892" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(61 290) rotate(90) scale(12)">
                    <stop offset="0.208333" stop-color="white"/>
                    <stop offset="0.916667" stop-color="white" stop-opacity="0"/>
                </radialGradient>
            </defs>
        </svg>
    )
}