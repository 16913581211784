import styled from "styled-components";
import {Header} from "../components/header";
import React, {useState} from "react";
import {useMeStore} from "../store/me-store";
import {Profile} from "../components/icons/profile";
import {MenuSwitch} from "../components/menu-switch";
import {Menu} from "../components/menu";
import {useSendClicksOnEveryPage} from "../hooks/useSendClicksOnEveryPage";
import {useTopUsers} from "../api/requests/leaders";
import {Loader} from "../components/loader";
import {TopUser} from "../api/requests/model";

export const LeaderBord = () => {


    const {data: topUsers, isLoading: isUsersLoading} = useTopUsers()
    useSendClicksOnEveryPage()

    const {user} = useMeStore()
    const [activeTab, setActiveTab] = useState({label: 'Каналы', value: 'channels'})


    if (isUsersLoading) {
        return <Loader/>
    }

    return (
        <Container>
            <Header user={user || null}/>
            <Title>
                Лидеры
            </Title>
            <AllWrapper>
                <MenuSwitch
                    activeTab={activeTab.value}
                    onChange={(tab) => setActiveTab({label: tab, value: tab})}
                    tabs={[{label: 'Каналы', value: 'channels'}, {
                        label: 'Игроки',
                        value: 'players'
                    }]}/>

                <Wrapper>
                    {(activeTab.value === 'players' && topUsers) &&
                        <>
                            {topUsers?.result?.users?.map((item, index) => (
                                <PlayerItem {...item} place={index + 1} key={index}/>
                            ))}
                        </>
                    }
                    {activeTab.value === 'channels' &&
                        <>
                            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item, index) => (
                                //@ts-ignore
                                <ChannelItem place={item} key={index}/>
                            ))}
                        </>
                    }
                </Wrapper>
            </AllWrapper>
            <Menu isAbsolute={true}/>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    height: 100vh;
    width: 100%;
    background-image: url('/bg/main-bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    position: relative;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */

    &::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
    }

    -webkit-overflow-scrolling: touch;
    overscroll-behavior: none;
    overflow: scroll
    // remove additional sc
`;


const Title = styled.div`
    color: #FFF;
    text-align: center;

    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px; /* 125% */
    margin-top: 16px;
    margin-bottom: 16px;
`


const AllWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 8px 10px 10px 10px;
    height: 100%;
    overflow: hidden;
    width: 100%;
    box-sizing: border-box;
    align-items: center;
    gap: 16px;
`


const Wrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    flex-direction: column;
    gap: 4px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    align-items: center;
    justify-content: center;
    width: 100%;
    justify-items: center; /* Center items horizontally within their columns */
    overflow: auto;

    padding-bottom: 70px;
`

interface PlayerItemProps extends TopUser {
    place: number

}

const PlayerItem = (props: PlayerItemProps) => {
    return (
        <ItemContainer>
            <Row>

                <Profile/>
                <Column>
                    <Name>
                        {props.username || props.first_name || props.last_name}
                    </Name>
                    <CoinRow>
                        Монеты:
                        <Gold>
                            <img
                                style={{width: 14, height: 14}}
                                src={'/images/icon-coin.png'} alt={'coin'}/>
                            {props.coin_cnt}
                        </Gold>
                    </CoinRow>
                </Column>


            </Row>

            {props.place === 1 && <FirstPlace/>}
            {props.place === 2 && <SecondPlace/>}
            {props.place === 3 && <ThirdPlace/>}
            {props.place > 3 && <OtherPlace>{props.place}</OtherPlace>}

        </ItemContainer>
    )
}


const ChannelItem = ({place}: PlayerItemProps) => {
    return (
        <ItemContainer>
            <Row>

                <Profile/>
                <Column>
                    <Name>
                        Дневники будущего
                    </Name>
                    <CoinRow>
                        Монеты:
                        <Gold>
                            <img
                                style={{width: 14, height: 14}}
                                src={'/images/icon-coin.png'} alt={'coin'}/>
                            10 000
                        </Gold>
                    </CoinRow>
                    <CoinRow>
                        Открыто сундуков:
                        <span style={{color: 'white'}}>
                            10 000
                        </span>
                    </CoinRow>
                </Column>


            </Row>

            <Column style={{alignItems: 'center'}}>
                <OtherPlace>
                    {place}


                </OtherPlace>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path
                        d="M14.9429 8.00005L9.13813 2.19531L8.19532 3.13812L12.3906 7.33338L1.3334 7.33338L1.3334 8.66672L12.3906 8.66672L8.19532 12.862L9.13813 13.8048L14.9429 8.00005Z"
                        fill="url(#paint0_linear_430_194567)"/>
                    <defs>
                        <linearGradient id="paint0_linear_430_194567" x1="14.9429" y1="8.0344" x2="1.88217" y2="8.03442"
                                        gradientUnits="userSpaceOnUse">
                            <stop stop-color="#83DA0F"/>
                            <stop offset="1" stop-color="#1EB649"/>
                        </linearGradient>
                    </defs>
                </svg>
            </Column>


        </ItemContainer>
    )
}


const ItemContainer = styled.div`
    display: flex;
    padding: 10px 12px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 16px;
    background: rgba(49, 74, 95, 0.30);
    backdrop-filter: blur(5px);
    width: 100%;
    box-sizing: border-box;
    justify-content: space-between;
`

const Row = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
`


const Column = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 2px;
`

const Name = styled.div`
    color: #FFF;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 133.333% */
`

const CoinRow = styled.div`
    color: #a2abb2;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
    gap: 8px;
    display: flex;
    align-items: center;

`

const OtherPlace = styled.div`
    color: #a2abb2;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 155.556% */
    display: flex;
    width: 24px;
    height: 24px;
    flex-direction: column;
    justify-content: center;
    gap: 4px;
`

const Gold = styled.span`
    color: #FFDA00;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 133.333% */
    display: flex;
    align-items: center;
    gap: 4px;
`


const FirstPlace = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <g clip-path="url(#clip0_419_235368)">
            <path
                d="M7.26693 16.646L6.31546 16.6924L4.47074 21.9924C4.41196 22.1612 4.54874 22.3338 4.72654 22.3152L7.3123 22.0444C7.38454 22.0369 7.45635 22.0618 7.50829 22.1126L9.36711 23.9304C9.49489 24.0553 9.7093 24.0049 9.76803 23.8362L11.6248 18.5015L11.0773 17.9702L7.26693 16.646Z"
                fill="#FF4755"/>
            <path
                d="M11.6248 18.5015L12 17.4236L6.68949 15.6177L6.31543 16.6924C7.8234 17.7684 9.64927 18.4271 11.6248 18.5015Z"
                fill="#FC2B3A"/>
            <path
                d="M16.7331 16.646L17.6845 16.6924L19.5293 21.9924C19.588 22.1612 19.4513 22.3338 19.2735 22.3152L16.6877 22.0444C16.6155 22.0369 16.5437 22.0618 16.4918 22.1126L14.6329 23.9304C14.5052 24.0553 14.2907 24.0049 14.232 23.8362L12.3752 18.5015L12.9227 17.9702L16.7331 16.646Z"
                fill="#FF4755"/>
            <path
                d="M12.3752 18.5015L12 17.4236L17.3105 15.6177L17.6845 16.6924C16.1766 17.7684 14.3507 18.4271 12.3752 18.5015Z"
                fill="#FC2B3A"/>
            <path
                d="M12.0001 16.8804C3.28824 16.8804 3.29189 8.41814 3.29911 8.27269C3.52782 3.66534 7.33589 0 12.0001 0C16.6642 0 20.4723 3.66534 20.701 8.27273C20.7082 8.41819 20.7119 16.8804 12.0001 16.8804Z"
                fill="#FFE27A"/>
            <path
                d="M12 16.5454C7.33586 16.5454 3.52793 12.8801 3.29918 8.27271C3.29196 8.41816 3.28821 8.56455 3.28821 8.71178C3.28821 13.5232 7.18863 17.4236 12 17.4236C16.8114 17.4236 20.7118 13.5232 20.7118 8.71178C20.7118 8.5645 20.7081 8.41816 20.7009 8.27271C20.4721 12.8801 16.6642 16.5454 12 16.5454Z"
                fill="#F9CF58"/>
            <path
                d="M12 15.0059C5.15075 15.0059 5.14156 8.27082 5.15075 8.1256C5.3772 4.53985 8.35714 1.70166 12 1.70166C15.6428 1.70166 18.6228 4.53985 18.8492 8.1256C18.8584 8.27077 18.8492 15.0059 12 15.0059Z"
                fill="#FFBA57"/>
            <path
                d="M12 14.6967C8.35723 14.6967 5.37781 11.8584 5.15135 8.27271C5.14217 8.41792 5.13696 8.56427 5.13696 8.71178C5.13696 12.5021 8.20967 15.5748 12 15.5748C15.7904 15.5748 18.8631 12.5021 18.8631 8.71178C18.8631 8.56422 18.8579 8.41792 18.8487 8.27271C18.6222 11.8584 15.6428 14.6967 12 14.6967Z"
                fill="#FFAC3E"/>
            <path
                d="M12.3914 12.7885C11.9149 12.7885 11.5286 12.4023 11.5286 11.9257V11.4694V7.09052C11.5286 7.09052 11.0124 7.60825 10.3947 7.29761C9.91309 7.05541 9.91309 6.43202 9.91309 6.43202L10.9768 4.63643C11.1322 4.37421 11.4144 4.21338 11.7191 4.21338H12.3914C12.8679 4.21338 13.2541 4.59963 13.2541 5.07616V11.4694V11.9257C13.2541 12.4023 12.8679 12.7885 12.3914 12.7885Z"
                fill="#FFE27A"/>
            <path
                d="M11.4101 6.41248C11.1811 6.79906 10.6942 6.96669 10.2881 6.77426C10.1238 6.69641 9.99707 6.57542 9.91284 6.43255C9.67326 6.83783 9.80296 7.35922 10.2019 7.60555C10.6132 7.85961 11.1629 7.70792 11.4093 7.29191L11.5287 7.09039V6.44487C11.5287 6.38009 11.4431 6.3567 11.4101 6.41248Z"
                fill="#F9CF58"/>
            <path
                d="M12.3913 12.332C11.9149 12.332 11.5286 11.9458 11.5286 11.4692V12.3474C11.5286 12.824 11.9149 13.2102 12.3913 13.2102C12.8678 13.2102 13.2541 12.824 13.2541 12.3474V11.4692C13.2541 11.9458 12.8678 12.332 12.3913 12.332Z"
                fill="#F9CF58"/>
        </g>
        <defs>
            <clipPath id="clip0_419_235368">
                <rect width="24" height="24" fill="white"/>
            </clipPath>
        </defs>
    </svg>
)


const SecondPlace = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <g clip-path="url(#clip0_419_235470)">
            <path
                d="M11.0376 17.9434L7.16896 16.6055L6.31541 16.6924L4.47074 21.9924C4.41196 22.1612 4.54874 22.3338 4.72654 22.3152L7.3123 22.0444C7.38454 22.0369 7.45635 22.0619 7.50829 22.1126L9.36711 23.9304C9.49489 24.0554 9.7093 24.005 9.76803 23.8362L11.6248 18.5016L11.0376 17.9434Z"
                fill="#98DB7C"/>
            <path
                d="M11.6248 18.5015L12 17.4236L6.68949 15.6177L6.31543 16.6924C7.8234 17.7684 9.64927 18.4271 11.6248 18.5015Z"
                fill="#82D361"/>
            <path
                d="M12.9624 17.9434L16.831 16.6055L17.6845 16.6924L19.5293 21.9924C19.588 22.1612 19.4513 22.3338 19.2735 22.3152L16.6877 22.0444C16.6155 22.0369 16.5437 22.0619 16.4918 22.1126L14.6329 23.9304C14.5052 24.0554 14.2907 24.005 14.232 23.8362L12.3752 18.5016L12.9624 17.9434Z"
                fill="#98DB7C"/>
            <path
                d="M12.3752 18.5015L12 17.4236L17.3105 15.6177L17.6845 16.6924C16.1766 17.7684 14.3507 18.4271 12.3752 18.5015Z"
                fill="#82D361"/>
            <path
                d="M12 16.8487C3.29909 16.8487 3.29187 8.41819 3.29909 8.27273C3.52779 3.66534 7.33587 0 12 0C16.6611 0 20.4672 3.66047 20.7005 8.2635C20.708 8.412 20.7005 16.8487 12 16.8487Z"
                fill="#E0E0E0"/>
            <path
                d="M12 16.5452C7.33586 16.5452 3.52793 12.8799 3.29918 8.27246C3.29196 8.41791 3.28821 8.5643 3.28821 8.71154C3.28821 13.5229 7.18863 17.4234 12 17.4234C16.8114 17.4234 20.7118 13.5229 20.7118 8.71154C20.7118 8.56426 20.7081 8.41791 20.7009 8.27246C20.4721 12.8799 16.6642 16.5452 12 16.5452Z"
                fill="#CECECE"/>
            <path
                d="M12 15.0294C5.13697 15.0294 5.14161 8.41779 5.1508 8.27257C5.37725 4.68682 8.35719 1.84863 12 1.84863C15.6429 1.84863 18.6228 4.68682 18.8493 8.27257C18.8585 8.41779 18.8493 15.0294 12 15.0294Z"
                fill="#9E9E9E"/>
            <path
                d="M12 14.6969C8.35723 14.6969 5.37781 11.8586 5.15135 8.27295C5.14217 8.41817 5.13696 8.56451 5.13696 8.71203C5.13696 12.5024 8.20967 15.5751 12 15.5751C15.7904 15.5751 18.8631 12.5024 18.8631 8.71203C18.8631 8.56447 18.8579 8.41817 18.8487 8.27295C18.6222 11.8586 15.6428 14.6969 12 14.6969Z"
                fill="#898989"/>
            <path
                d="M13.9756 12.7885H10.0418C9.56531 12.7885 9.19739 11.9591 9.23325 11.7695C9.33277 11.2431 9.567 10.7471 9.91673 10.3337L12.6847 7.06235C12.9436 6.75625 12.8281 6.42644 12.7865 6.3326C12.7359 6.21855 12.5786 5.95188 12.2123 5.9395C12.2017 5.93913 12.1906 5.93894 12.1796 5.93894C11.665 5.93894 11.2463 6.35763 11.2463 6.87227C11.2463 7.34875 10.86 7.73505 10.3836 7.73505C9.90708 7.73505 9.52078 7.3488 9.52078 6.87227C9.52078 5.40621 10.7135 4.21338 12.1796 4.21338C12.21 4.21338 12.2401 4.21389 12.2702 4.21493C13.1875 4.24586 13.9897 4.78933 14.3639 5.63313C14.7436 6.48954 14.6049 7.46425 14.0019 8.17694L11.234 11.4483C11.2238 11.4603 11.2139 11.4725 11.2041 11.4849H13.9757C14.2916 11.4849 14.5679 11.6547 14.7182 11.9081C14.7945 12.0368 14.4521 12.7885 13.9756 12.7885Z"
                fill="#E0E0E0"/>
            <path
                d="M13.9756 12.3321H10.0417C9.67096 12.3321 9.35572 12.0978 9.23371 11.7695C9.19785 11.9591 9.17896 12.1525 9.17896 12.3475C9.17896 12.8241 9.56525 13.2103 10.0417 13.2103H13.9756C14.4521 13.2103 14.8384 12.8241 14.8384 12.3475C14.8384 12.187 14.7937 12.0372 14.7173 11.9085C14.567 12.1618 14.2915 12.3321 13.9756 12.3321Z"
                fill="#CECECE"/>
        </g>
        <defs>
            <clipPath id="clip0_419_235470">
                <rect width="24" height="24" fill="white"/>
            </clipPath>
        </defs>
    </svg>
)


const ThirdPlace = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <g clip-path="url(#clip0_419_235499)">
            <path
                d="M10.9675 17.8974L7.37 16.6983L6.31541 16.6924L4.47074 21.9923C4.41196 22.1611 4.54874 22.3337 4.72654 22.3151L7.3123 22.0443C7.38454 22.0368 7.45635 22.0618 7.50829 22.1125L9.36711 23.9303C9.49489 24.0553 9.7093 24.0049 9.76803 23.8361L11.6248 18.5015L10.9675 17.8974Z"
                fill="#0083FD"/>
            <path
                d="M11.6248 18.5015L12 17.4236L6.68949 15.6177L6.31543 16.6924C7.8234 17.7684 9.64927 18.4271 11.6248 18.5015Z"
                fill="#0072FC"/>
            <path
                d="M13.0325 17.8974L16.6299 16.6983L17.6845 16.6924L19.5293 21.9924C19.588 22.1611 19.4513 22.3338 19.2735 22.3151L16.6877 22.0444C16.6155 22.0368 16.5437 22.0618 16.4918 22.1126L14.6329 23.9304C14.5052 24.0553 14.2907 24.0049 14.232 23.8361L12.3752 18.5015L13.0325 17.8974Z"
                fill="#0083FD"/>
            <path
                d="M12.3752 18.5015L12 17.4236L17.3105 15.6177L17.6845 16.6924C16.1766 17.7684 14.3507 18.4271 12.3752 18.5015Z"
                fill="#0072FC"/>
            <path
                d="M12 16.6924C3.29909 16.6924 3.29187 8.41819 3.29909 8.27273C3.52779 3.66534 7.33587 0 12 0C16.6642 0 20.4723 3.66534 20.701 8.27273C20.7082 8.41819 20.701 16.6924 12 16.6924Z"
                fill="#FF9838"/>
            <path
                d="M12 16.5456C7.33586 16.5456 3.52793 12.8803 3.29918 8.27295C3.29196 8.4184 3.28821 8.56479 3.28821 8.71203C3.28821 13.5234 7.18863 17.4238 12 17.4238C16.8114 17.4238 20.7118 13.5234 20.7118 8.71203C20.7118 8.56479 20.7081 8.4184 20.7009 8.27295C20.4721 12.8803 16.6642 16.5456 12 16.5456Z"
                fill="#FF8709"/>
            <path
                d="M18.8631 8.7117C18.8631 11.6505 15.7904 15.1529 12 15.1529C8.20967 15.1529 5.13696 11.4578 5.13696 8.7117C5.13696 8.56418 5.1416 8.41779 5.15079 8.27257C5.3772 4.68682 8.35718 1.84863 12 1.84863C15.6429 1.84863 18.6229 4.68682 18.8493 8.27257C18.8584 8.41779 18.8631 8.56418 18.8631 8.7117Z"
                fill="#CE7430"/>
            <path
                d="M12 14.6969C8.35723 14.6969 5.37781 11.8586 5.15135 8.27295C5.14217 8.41817 5.13696 8.56447 5.13696 8.71203C5.13696 12.5024 8.20967 15.5751 12 15.5751C15.7904 15.5751 18.8631 12.5024 18.8631 8.71203C18.8631 8.56447 18.8579 8.41817 18.8487 8.27295C18.6222 11.8586 15.6428 14.6969 12 14.6969Z"
                fill="#BC672E"/>
            <path
                d="M12.0087 12.7884C10.5306 12.7884 9.59011 11.7695 9.40908 10.5606C9.39263 10.4506 9.38742 10.2146 9.38742 10.2146C9.51328 9.89393 9.82556 9.66686 10.1909 9.66686C10.6673 9.66686 11.0536 10.0531 11.0536 10.5296C11.0536 11.0563 11.4821 11.4847 12.0087 11.4847C12.5354 11.4847 12.9638 11.0563 12.9638 10.5296C12.9638 10.3713 12.9251 10.2219 12.8566 10.0902C12.8566 10.0902 12.7919 9.27668 12.0549 9.27668C11.3157 9.27668 11.2662 8.27224 11.2662 8.27224C11.4165 8.01888 11.6928 7.84905 12.0088 7.84905C12.5354 7.84905 12.9639 7.42061 12.9639 6.89397C12.9639 6.36733 12.5354 5.93889 12.0088 5.93889C11.4821 5.93889 11.0537 6.36733 11.0537 6.89397C11.0537 7.3705 10.6674 7.75675 10.1909 7.75675C9.71442 7.75675 9.32812 7.3705 9.32812 6.89397C9.32812 5.41591 10.5307 4.21338 12.0087 4.21338C13.4868 4.21338 14.6894 5.41591 14.6894 6.89397C14.6894 7.59485 14.4191 8.23366 13.9771 8.71183C14.3249 9.08805 14.5663 9.56374 14.6535 10.0908C14.6535 10.0908 14.6562 10.3802 14.6311 10.5275C14.4172 11.7848 13.4868 12.7884 12.0087 12.7884Z"
                fill="#FFAA5C"/>
            <path
                d="M12.0086 8.69661C11.6927 8.69661 11.4172 8.52626 11.2669 8.27295C11.1905 8.40167 11.1459 8.55148 11.1459 8.71203C11.1459 9.18856 11.5321 9.57481 12.0087 9.57481C12.377 9.57481 12.6966 9.78471 12.8559 10.0909C12.9244 9.95923 12.9638 9.81012 12.9638 9.65178C12.9637 9.12504 12.5352 8.69661 12.0086 8.69661Z"
                fill="#EA9957"/>
            <path
                d="M12.0086 12.3324C10.7238 12.3324 9.64769 11.4237 9.3881 10.2153C9.3498 10.3129 9.328 10.4188 9.328 10.5301C9.328 12.0082 10.5305 13.2107 12.0086 13.2107C13.4868 13.2107 14.6893 12.0082 14.6893 10.5301C14.6893 10.3806 14.6763 10.2342 14.6527 10.0913C14.4423 11.361 13.3371 12.3324 12.0086 12.3324Z"
                fill="#EA9957"/>
        </g>
        <defs>
            <clipPath id="clip0_419_235499">
                <rect width="24" height="24" fill="white"/>
            </clipPath>
        </defs>
    </svg>
)