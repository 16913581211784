import copy from "copy-to-clipboard";
import { useState } from "react";

export const useCopy = (text: string) => {
    const [isCopied, setIsCopied] = useState(false);

    const handleCopy = () => {
        console.log('copy', text)
        copy(text);
        setIsCopied(true);
        setTimeout(() => {
            setIsCopied(false);
        }, 2000);
    };

    return [isCopied, handleCopy] as const;
};
