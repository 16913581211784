export const SDeckBG = (props: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg" width="327" height="288" viewBox="0 0 327 288" fill="none">
            <g filter="url(#filter0_d_130_19389)">
                <g filter="url(#filter1_i_130_19389)">
                    <rect x="4" y="3" width="319" height="280" rx="33" fill="url(#paint0_linear_130_19389)"/>
                </g>
                <rect x="9" y="3" width="309" height="270" rx="33" fill="url(#paint1_linear_130_19389)"/>
                <mask id="mask0_130_19389"
                      style={{maskType: 'alpha'}}
                      maskUnits="userSpaceOnUse" x="9" y="3" width="309" height="270">
                    <rect x="9" y="3" width="309" height="270" rx="33" fill="url(#paint2_linear_130_19389)"/>
                </mask>
                <g mask="url(#mask0_130_19389)">
                    <g opacity="0.39" filter="url(#filter2_dif_130_19389)">
                        <path
                            d="M103.545 24.3513C75.9482 24.9952 21.9203 15.7664 2 12.2787V14.6933C3.29563 15.4981 3.16607 18.7174 5.88689 18.7174C9.28793 18.7174 28.7224 22.4733 55.4448 24.3513C82.1672 26.2293 122.98 23.5465 178.854 22.2051C234.728 20.8637 300.481 26.8552 323.641 28.1072C324.288 26.8552 324.515 24.3513 320.239 24.3513C314.895 24.3513 301.777 21.132 258.049 18.7174C214.322 16.3029 138.041 23.5465 103.545 24.3513Z"
                            fill="#E5AA88"/>
                    </g>
                    <g opacity="0.39" filter="url(#filter3_dif_130_19389)">
                        <path
                            d="M2 163.468C21.9148 166.966 75.9278 176.221 103.517 175.576C112.153 175.373 124.899 174.986 139.843 174.556C146.584 174.346 153.871 174.138 161.805 173.947C203.737 172.839 254.074 171.888 281.779 173.423C325.495 175.845 325.009 165.889 330.352 165.889C334.627 165.889 335.209 178.804 320.638 182.571C317.237 175.576 217.663 172.602 161.805 173.947C154.14 174.15 146.757 174.357 139.843 174.556C99.1261 175.826 78.3507 177.191 55.4301 175.576C28.715 173.692 9.28592 169.925 5.88582 169.925C3.16575 169.925 3.29527 166.697 2 165.889V163.468Z"
                            fill="#E5AA88"/>
                    </g>
                    <g opacity="0.39" filter="url(#filter4_dif_130_19389)">
                        <path
                            d="M103.545 261.232C75.9482 261.876 21.9203 252.647 2 249.16V251.574C3.29563 252.379 3.16607 255.599 5.88689 255.599C9.28793 255.599 28.7224 259.354 55.4448 261.232C82.1672 263.11 122.98 260.428 178.854 259.086C234.728 257.745 300.481 263.736 323.641 264.988C324.288 263.736 324.515 261.232 320.239 261.232C314.895 261.232 301.777 258.013 258.049 255.599C214.322 253.184 138.041 260.428 103.545 261.232Z"
                            fill="#E5AA88"/>
                    </g>
                    <g opacity="0.39" filter="url(#filter5_dif_130_19389)">
                        <path
                            d="M0.62851 54.306C24.5079 55.9195 118.602 62.6902 147.688 62.2612C148.105 62.2533 148.522 62.2453 148.939 62.2373C183.351 61.4186 264.776 55.1247 308.397 57.5809C352.017 60.037 315.182 61.6745 320.513 61.6745C324.779 61.6745 325.522 66.1319 324.876 67.4054C306.458 62.7661 230.364 60.3099 174.627 61.6745C166.101 61.8832 157.495 62.0727 148.939 62.2373C148.536 62.2469 148.119 62.2548 147.688 62.2612C100.753 63.1509 55.4823 63.2783 33.1017 61.6745C6.4446 59.7641 0.628503 59.4912 -2.76422 59.4912C-5.47839 59.4912 -18.7585 63.5848 -7.61096 59.4912L0.62851 54.306Z"
                            fill="#E5AA88"/>
                    </g>
                    <g opacity="0.39" filter="url(#filter6_dif_130_19389)">
                        <path
                            d="M-0.184657 119.904C-0.184663 119.904 -0.184669 119.904 -0.184675 119.904L-0.1845 119.904C0.828233 119.307 1.95785 118.721 3.20701 118.166L-0.1845 119.904C-0.184552 119.904 -0.184604 119.904 -0.184657 119.904C19.6803 123.452 76.9495 128.645 104.47 127.99C119.539 127.632 145.394 126.697 173.359 125.966C175.408 125.911 177.478 125.858 179.569 125.807C179.971 125.797 180.373 125.788 180.776 125.779C214.554 124.956 250.255 124.521 273.079 125.807C316.685 128.263 318.624 122.259 323.953 122.259C328.217 122.259 324.599 130.537 323.953 131.811C301.025 130.547 236.231 124.53 180.776 125.779C178.291 125.839 175.817 125.902 173.359 125.966C120.815 127.37 82.1512 129.829 56.5032 127.99C29.8551 126.08 -2.60702 127.99 -5.9986 127.99C-8.34366 127.99 -6.63506 123.709 -0.184657 119.904Z"
                            fill="#E5AA88"/>
                    </g>
                    <g opacity="0.39" filter="url(#filter7_dif_130_19389)">
                        <path
                            d="M324 206.587C295.037 216.31 226.58 218.752 199.042 218.108C183.569 217.576 166.409 216.967 147.525 216.513C145.618 216.467 143.699 216.43 141.771 216.401C111.723 215.596 78.7208 215.123 53.5957 216.513C9.96176 218.928 8.38123 216.513 3.04819 216.513C-1.21823 216.513 2.40176 221.163 3.04819 222.415C25.3676 221.206 87.3318 215.576 141.771 216.401C163.907 216.994 184.441 217.767 199.042 218.108C229.254 219.149 253.036 219.901 270.67 218.659C297.335 216.781 316.728 213.025 320.121 213.025C322.836 213.025 322.707 209.806 324 209.001V206.587Z"
                            fill="#E5AA88"/>
                    </g>
                    <g opacity="0.39" filter="url(#filter8_dif_130_19389)">
                        <path
                            d="M222.77 99.673C250.281 100.317 304.142 91.088 324 87.6004V90.0149C322.708 90.8198 322.838 94.0391 320.125 94.0391C316.735 94.0391 297.361 97.795 270.721 99.673C244.082 101.551 203.396 98.8681 147.696 97.5267C91.995 96.1853 26.4459 102.177 3.3584 103.429C2.71259 102.177 2.48654 99.673 6.74887 99.673C12.0768 99.673 25.1543 96.4536 68.746 94.0391C112.338 91.6246 188.381 98.8681 222.77 99.673Z"
                            fill="#E5AA88"/>
                    </g>
                    <g opacity="0.39" filter="url(#filter9_dif_130_19389)">
                        <path
                            d="M23.4774 24.1947C19.7003 21.4853 6.49593 20.3372 1.79416 23.0316C-0.26127 24.2095 -0.578532 25.8707 -1.38056 26.8304C-2.3831 28.03 -8.71844 30.2953 -5.89198 32.2286C-3.06552 34.162 1.06564 34.0027 1.92607 36.1623C2.78649 38.322 2.68342 39.5085 9.27485 41.1891C15.8663 42.8698 14.9738 40.2168 17.9104 38.2976C20.8469 36.3785 27.9076 36.9661 30.9723 37.0199C34.0369 37.0737 31.9818 33.1541 26.8231 31.3537C21.6645 29.5533 28.1989 27.5814 23.4774 24.1947Z"
                            fill="#E5AA88"/>
                    </g>
                    <g opacity="0.39" filter="url(#filter10_dif_130_19389)">
                        <path
                            d="M16.6726 159.134C20.4874 157.686 22.5718 152.286 19.1192 150.238C17.6098 149.343 15.377 149.145 14.1121 148.776C12.5309 148.314 9.72422 145.61 6.99675 146.697C4.26928 147.785 4.31808 149.495 1.36395 149.763C-1.59018 150.031 -3.1898 149.941 -5.72747 152.591C-8.26515 155.242 -4.64296 154.981 -2.16708 156.269C0.308808 157.558 -0.770246 160.446 -0.96653 161.708C-1.16281 162.97 4.21839 162.28 6.86018 160.225C9.50197 158.17 11.9041 160.945 16.6726 159.134Z"
                            fill="#E5AA88"/>
                    </g>
                    <g opacity="0.39" filter="url(#filter11_dif_130_19389)">
                        <path
                            d="M29.2925 120.558C25.1327 119.888 14.4943 118.502 14.1217 115.712C13.9588 114.492 18.6916 113.531 19.0607 112.768C19.5221 111.813 17.2998 109.178 20.5981 108.812C23.8964 108.446 29.2785 109.643 31.642 108.628C34.0054 107.613 38.857 106.823 43.0568 105.217C47.2565 103.612 43.0285 109.673 43.0568 111.552C43.085 113.431 42.9024 113.574 44.7114 114.352C46.5204 115.13 42.3778 115.781 37.8533 115.455C33.3288 115.128 34.8477 121.453 29.2925 120.558Z"
                            fill="#E5AA88"/>
                    </g>
                    <g opacity="0.39" filter="url(#filter12_dif_130_19389)">
                        <path
                            d="M25.0881 245.724C30.7979 243.555 33.9179 235.474 28.75 232.409C26.4909 231.069 23.1489 230.773 21.2556 230.22C18.889 229.528 14.6881 225.481 10.6057 227.109C6.52338 228.737 6.59644 231.297 2.17482 231.698C-2.2468 232.098 -4.64103 231.963 -8.43932 235.931C-12.2376 239.898 -6.81607 239.507 -3.11027 241.436C0.595528 243.364 -1.01955 247.687 -1.31334 249.576C-1.60713 251.465 6.44722 250.433 10.4013 247.357C14.3555 244.281 17.9509 248.434 25.0881 245.724Z"
                            fill="#E5AA88"/>
                    </g>
                    <g opacity="0.39" filter="url(#filter13_dif_130_19389)">
                        <path
                            d="M300.641 155.897C300.928 159.721 311.659 165.479 319.107 164.691C322.363 164.347 324.594 162.942 326.449 162.35C328.767 161.611 337.207 161.798 336.882 159.023C336.557 156.247 332.594 154.92 334.331 152.639C336.068 150.357 337.549 149.309 333.486 145.423C329.424 141.536 327.139 144.28 322.212 144.987C317.284 145.695 311.515 142.641 308.776 141.499C306.037 140.357 303.335 144.673 305.948 148.159C308.561 151.644 300.282 151.117 300.641 155.897Z"
                            fill="#E5AA88"/>
                    </g>
                    <g opacity="0.39" filter="url(#filter14_dif_130_19389)">
                        <path
                            d="M314.549 71.2342C310.206 71.9848 305.418 76.7232 307.588 79.1989C308.536 80.2811 310.53 80.824 311.525 81.3733C312.768 82.06 313.987 85.0489 317.119 84.4633C320.251 83.8777 321.101 82.2635 324.017 82.4844C326.932 82.7052 328.387 83.0459 332.16 80.9619C335.933 78.8779 332.394 78.5439 330.743 76.9377C329.093 75.3314 331.62 72.791 332.466 71.6372C333.312 70.4834 327.896 70.2705 324.337 71.7783C320.779 73.2861 319.977 70.2959 314.549 71.2342Z"
                            fill="#E5AA88"/>
                    </g>
                </g>
            </g>
            <defs>
                <filter id="filter0_d_130_19389" x="0.6" y="0.6" width="325.8" height="286.8"
                        filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                   result="hardAlpha"/>
                    <feOffset dy="1"/>
                    <feGaussianBlur stdDeviation="1.7"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix"
                                   values="0 0 0 0 0.156863 0 0 0 0 0.168627 0 0 0 0 0.376471 0 0 0 0.57 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_130_19389"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_130_19389" result="shape"/>
                </filter>
                <filter id="filter1_i_130_19389" x="4" y="-1" width="319" height="284" filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                   result="hardAlpha"/>
                    <feOffset dy="-4"/>
                    <feGaussianBlur stdDeviation="2"/>
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0.4375 0 0 0 0 0.253906 0 0 0 0 0.109375 0 0 0 0.9 0"/>
                    <feBlend mode="normal" in2="shape" result="effect1_innerShadow_130_19389"/>
                </filter>
                <filter id="filter2_dif_130_19389" x="0" y="10.6787" width="326" height="22.4284"
                        filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                   result="hardAlpha"/>
                    <feOffset dy="3"/>
                    <feGaussianBlur stdDeviation="1"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.94061 0 0 0 0 0.808333 0 0 0 1 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_130_19389"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_130_19389" result="shape"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                   result="hardAlpha"/>
                    <feOffset dy="3"/>
                    <feGaussianBlur stdDeviation="1"/>
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0.825 0 0 0 0 0.626096 0 0 0 0 0.495 0 0 0 1 0"/>
                    <feBlend mode="normal" in2="shape" result="effect2_innerShadow_130_19389"/>
                    <feGaussianBlur stdDeviation="0.8" result="effect3_foregroundBlur_130_19389"/>
                </filter>
                <filter id="filter3_dif_130_19389" x="0" y="161.868" width="335" height="25.7033"
                        filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                   result="hardAlpha"/>
                    <feOffset dy="3"/>
                    <feGaussianBlur stdDeviation="1"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.94061 0 0 0 0 0.808333 0 0 0 1 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_130_19389"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_130_19389" result="shape"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                   result="hardAlpha"/>
                    <feOffset dy="3"/>
                    <feGaussianBlur stdDeviation="1"/>
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0.825 0 0 0 0 0.626096 0 0 0 0 0.495 0 0 0 1 0"/>
                    <feBlend mode="normal" in2="shape" result="effect2_innerShadow_130_19389"/>
                    <feGaussianBlur stdDeviation="0.8" result="effect3_foregroundBlur_130_19389"/>
                </filter>
                <filter id="filter4_dif_130_19389" x="0" y="247.56" width="326" height="22.4285"
                        filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                   result="hardAlpha"/>
                    <feOffset dy="3"/>
                    <feGaussianBlur stdDeviation="1"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.94061 0 0 0 0 0.808333 0 0 0 1 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_130_19389"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_130_19389" result="shape"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                   result="hardAlpha"/>
                    <feOffset dy="3"/>
                    <feGaussianBlur stdDeviation="1"/>
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0.825 0 0 0 0 0.626096 0 0 0 0 0.495 0 0 0 1 0"/>
                    <feBlend mode="normal" in2="shape" result="effect2_innerShadow_130_19389"/>
                    <feGaussianBlur stdDeviation="0.8" result="effect3_foregroundBlur_130_19389"/>
                </filter>
                <filter id="filter5_dif_130_19389" x="-14" y="52.706" width="346" height="19.6994"
                        filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                   result="hardAlpha"/>
                    <feOffset dy="3"/>
                    <feGaussianBlur stdDeviation="1"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.94061 0 0 0 0 0.808333 0 0 0 1 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_130_19389"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_130_19389" result="shape"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                   result="hardAlpha"/>
                    <feOffset dy="3"/>
                    <feGaussianBlur stdDeviation="1"/>
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0.825 0 0 0 0 0.626096 0 0 0 0 0.495 0 0 0 1 0"/>
                    <feBlend mode="normal" in2="shape" result="effect2_innerShadow_130_19389"/>
                    <feGaussianBlur stdDeviation="0.8" result="effect3_foregroundBlur_130_19389"/>
                </filter>
                <filter id="filter6_dif_130_19389" x="-9" y="116.566" width="337" height="20.2452"
                        filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                   result="hardAlpha"/>
                    <feOffset dy="3"/>
                    <feGaussianBlur stdDeviation="1"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.94061 0 0 0 0 0.808333 0 0 0 1 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_130_19389"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_130_19389" result="shape"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                   result="hardAlpha"/>
                    <feOffset dy="3"/>
                    <feGaussianBlur stdDeviation="1"/>
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0.825 0 0 0 0 0.626096 0 0 0 0 0.495 0 0 0 1 0"/>
                    <feBlend mode="normal" in2="shape" result="effect2_innerShadow_130_19389"/>
                    <feGaussianBlur stdDeviation="0.8" result="effect3_foregroundBlur_130_19389"/>
                </filter>
                <filter id="filter7_dif_130_19389" x="-1" y="204.987" width="327" height="22.4285"
                        filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                   result="hardAlpha"/>
                    <feOffset dy="3"/>
                    <feGaussianBlur stdDeviation="1"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.94061 0 0 0 0 0.808333 0 0 0 1 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_130_19389"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_130_19389" result="shape"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                   result="hardAlpha"/>
                    <feOffset dy="3"/>
                    <feGaussianBlur stdDeviation="1"/>
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0.825 0 0 0 0 0.626096 0 0 0 0 0.495 0 0 0 1 0"/>
                    <feBlend mode="normal" in2="shape" result="effect2_innerShadow_130_19389"/>
                    <feGaussianBlur stdDeviation="0.8" result="effect3_foregroundBlur_130_19389"/>
                </filter>
                <filter id="filter8_dif_130_19389" x="1" y="86.0004" width="325" height="22.4285"
                        filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                   result="hardAlpha"/>
                    <feOffset dy="3"/>
                    <feGaussianBlur stdDeviation="1"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.94061 0 0 0 0 0.808333 0 0 0 1 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_130_19389"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_130_19389" result="shape"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                   result="hardAlpha"/>
                    <feOffset dy="3"/>
                    <feGaussianBlur stdDeviation="1"/>
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0.825 0 0 0 0 0.626096 0 0 0 0 0.495 0 0 0 1 0"/>
                    <feBlend mode="normal" in2="shape" result="effect2_innerShadow_130_19389"/>
                    <feGaussianBlur stdDeviation="0.8" result="effect3_foregroundBlur_130_19389"/>
                </filter>
                <filter id="filter9_dif_130_19389" x="-8.6123" y="19.8966" width="43.0198" height="26.8265"
                        filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                   result="hardAlpha"/>
                    <feOffset dy="3"/>
                    <feGaussianBlur stdDeviation="1"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.94061 0 0 0 0 0.808333 0 0 0 1 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_130_19389"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_130_19389" result="shape"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                   result="hardAlpha"/>
                    <feOffset dy="3"/>
                    <feGaussianBlur stdDeviation="1"/>
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0.825 0 0 0 0 0.626096 0 0 0 0 0.495 0 0 0 1 0"/>
                    <feBlend mode="normal" in2="shape" result="effect2_innerShadow_130_19389"/>
                    <feGaussianBlur stdDeviation="0.8" result="effect3_foregroundBlur_130_19389"/>
                </filter>
                <filter id="filter10_dif_130_19389" x="-8.59106" y="144.842" width="31.4641" height="22.4996"
                        filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                   result="hardAlpha"/>
                    <feOffset dy="3"/>
                    <feGaussianBlur stdDeviation="1"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.94061 0 0 0 0 0.808333 0 0 0 1 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_130_19389"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_130_19389" result="shape"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                   result="hardAlpha"/>
                    <feOffset dy="3"/>
                    <feGaussianBlur stdDeviation="1"/>
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0.825 0 0 0 0 0.626096 0 0 0 0 0.495 0 0 0 1 0"/>
                    <feBlend mode="normal" in2="shape" result="effect2_innerShadow_130_19389"/>
                    <feGaussianBlur stdDeviation="0.8" result="effect3_foregroundBlur_130_19389"/>
                </filter>
                <filter id="filter11_dif_130_19389" x="12.1177" y="103.35" width="35.0281" height="22.2953"
                        filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                   result="hardAlpha"/>
                    <feOffset dy="3"/>
                    <feGaussianBlur stdDeviation="1"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.94061 0 0 0 0 0.808333 0 0 0 1 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_130_19389"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_130_19389" result="shape"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                   result="hardAlpha"/>
                    <feOffset dy="3"/>
                    <feGaussianBlur stdDeviation="1"/>
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0.825 0 0 0 0 0.626096 0 0 0 0 0.495 0 0 0 1 0"/>
                    <feBlend mode="normal" in2="shape" result="effect2_innerShadow_130_19389"/>
                    <feGaussianBlur stdDeviation="0.8" result="effect3_foregroundBlur_130_19389"/>
                </filter>
                <filter id="filter12_dif_130_19389" x="-11.7322" y="225.126" width="45.1074" height="30.3979"
                        filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                   result="hardAlpha"/>
                    <feOffset dy="3"/>
                    <feGaussianBlur stdDeviation="1"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.94061 0 0 0 0 0.808333 0 0 0 1 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_130_19389"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_130_19389" result="shape"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                   result="hardAlpha"/>
                    <feOffset dy="3"/>
                    <feGaussianBlur stdDeviation="1"/>
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0.825 0 0 0 0 0.626096 0 0 0 0 0.495 0 0 0 1 0"/>
                    <feBlend mode="normal" in2="shape" result="effect2_innerShadow_130_19389"/>
                    <feGaussianBlur stdDeviation="0.8" result="effect3_foregroundBlur_130_19389"/>
                </filter>
                <filter id="filter13_dif_130_19389" x="298.629" y="139.712" width="40.262" height="30.0527"
                        filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                   result="hardAlpha"/>
                    <feOffset dy="3"/>
                    <feGaussianBlur stdDeviation="1"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.94061 0 0 0 0 0.808333 0 0 0 1 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_130_19389"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_130_19389" result="shape"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                   result="hardAlpha"/>
                    <feOffset dy="3"/>
                    <feGaussianBlur stdDeviation="1"/>
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0.825 0 0 0 0 0.626096 0 0 0 0 0.495 0 0 0 1 0"/>
                    <feBlend mode="normal" in2="shape" result="effect2_innerShadow_130_19389"/>
                    <feGaussianBlur stdDeviation="0.8" result="effect3_foregroundBlur_130_19389"/>
                </filter>
                <filter id="filter14_dif_130_19389" x="305.048" y="69.1095" width="30.7893" height="20.4293"
                        filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                   result="hardAlpha"/>
                    <feOffset dy="3"/>
                    <feGaussianBlur stdDeviation="1"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.94061 0 0 0 0 0.808333 0 0 0 1 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_130_19389"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_130_19389" result="shape"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                   result="hardAlpha"/>
                    <feOffset dy="3"/>
                    <feGaussianBlur stdDeviation="1"/>
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0.825 0 0 0 0 0.626096 0 0 0 0 0.495 0 0 0 1 0"/>
                    <feBlend mode="normal" in2="shape" result="effect2_innerShadow_130_19389"/>
                    <feGaussianBlur stdDeviation="0.8" result="effect3_foregroundBlur_130_19389"/>
                </filter>
                <linearGradient id="paint0_linear_130_19389" x1="163.5" y1="3" x2="163.5" y2="283"
                                gradientUnits="userSpaceOnUse">
                    <stop stop-color="#F1C69E"/>
                    <stop offset="1" stop-color="#CC9E7A"/>
                </linearGradient>
                <linearGradient id="paint1_linear_130_19389" x1="163.5" y1="3" x2="163.5" y2="273"
                                gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FEDBB1"/>
                    <stop offset="1" stop-color="#D5AB87"/>
                </linearGradient>
                <linearGradient id="paint2_linear_130_19389" x1="163.5" y1="3" x2="163.5" y2="273"
                                gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FEDBB1"/>
                    <stop offset="1" stop-color="#D5AB87"/>
                </linearGradient>
            </defs>
        </svg>
    )
}