import styled from "styled-components";


interface Props {
    tabs: { label: string, value: string }[]
    activeTab: string
    onChange: (tab: string) => void
}

export const MenuSwitch = (props: Props) => {

    return (
        <Container>
            {props.tabs.map((tab, i) => {
                return (
                    <Tab
                        key={i}
                        active={props.activeTab === tab.value}
                        onClick={() => props.onChange(tab.value)}
                    >
                        {tab.label}
                    </Tab>
                )
            })}

        </Container>
    )

}


const Container = styled.div`
    display: flex;
     padding: 4px 5px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 100px;
    border: 1px solid #1C3246;
    background: #112232;
    position: sticky;
    top: 0;
    height: 52px;
    box-sizing: border-box;
 `


const Tab = styled.div<{ active?: boolean }>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
    border-radius: 160px;
    background: ${(props) => props.active ? '#1A2F43;' : 'transparent'};

    padding: 14px 20px;
    gap: 8px;
    align-self: stretch;

    color: ${(props) => props.active ? '#FFFFFF;' : '#5781a6;'};
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px; /* 128.571% */
`