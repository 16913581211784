import styled from "styled-components";
import {Coin} from "./icons/coin";


interface Props {
    title: string
    prize: number
}

export const PrizeItem = (props: Props) => {

    return (
        <Container>
            <Img src="/chests/gold_close.png" alt="prize"/>
            <Right>
                <Title>
                    {props.title}
                </Title>
                <Row>
                    <StyledCoin/>
                    <Col>
                        <P>
                            &nbsp;
                            +{props.prize}
                        </P>
                        <P2>
                            &nbsp;
                            Для вас и вашего друга
                        </P2>
                    </Col>
                </Row>
            </Right>
        </Container>
    )

}

const StyledCoin = styled(Coin)`
    width: 38px;
    height: 38px;
    @media (max-width: 380px) {
        width: 30px;
        height: 30px;
    }
`


const P2 = styled.span`
    color: white;
    font-size: 12px;
    font-weight: 500;
    line-height: 14.4px;
    text-align: left;
    display: flex;
    align-items: center;
    @media (max-width: 380px) {
        font-size: 10px;
    }
`


const Row = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;

`
const Col = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2px;
    align-items: flex-start;
    justify-content: center;
`

const P = styled.span`
    color: #FFD600;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.8px;
    text-align: right;
    display: flex;
    align-items: center;
    @media (max-width: 380px) {
        font-size: 12px;
    }
`

const Right = styled.span`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 4px;
    width: 80%;
`


const Title = styled.span`
    font-size: 16px;
    font-weight: 500;
    line-height: 19.2px;
    text-align: left;
    color: #fff;

`


const Img = styled.img`
    width: 76px;
    height: 77px;
`


const Container = styled.div`
    display: flex;
    padding: 10px 16px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 16px;
    border: 1px solid #384E63;
    background: rgba(49, 74, 95, 0.50);
    backdrop-filter: blur(5px);
    @media (max-width: 380px) {
        padding: 5px;
    }
`