import useSWR from "swr";
import {fetchSWR} from "../apiClient";
import {Chest, ChestResult, Response} from "./model";


export const useChestList = (skip?: boolean) => {
    return useSWR(skip ? undefined :
            '/api/user-chest/list',
        (url: any) => fetchSWR<Response<ChestResult[]>>({input: url}), {
            revalidateOnFocus: false
        }
    );
}

