import styled from "styled-components";
import {useWidth} from "../hooks/useWidth";

export const DesctopWindow = () => {
    const width = useWidth()
    return (
        <Container>
            <Text>
                Играйте в нашу игру на телефоне!
            </Text>
            <img src={'/qr.png'}
                 style={{
                     width: width * 0.8,
                     height: width * 0.8,
                     borderRadius: 10,
                 }}/>

            <Text style={{marginBottom:20}}>
                @diggerton_bot
            </Text>
        </Container>

    )
}

const Text = styled.div`
  font-size: 24px;
  font-weight: 500;
  line-height: 28.8px;
  text-align: center;
  color: rgba(255, 255, 255, 1);
  
`


const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 100vh;
  background: black;
  padding: 20px 0 20px 0;
`