import {create} from 'zustand';
import {State} from "../api/requests/model";



type S = {
    state: State | null;
    wasCalled: boolean;
    setWasCalled: (wasCalled: boolean) => void;
    setState: (state: State | null) => void;
};

export const useStateStore = create<S>((set) => ({
    state: null,
    setState: (state) => set({state}),
    wasCalled: false,
    setWasCalled: (wasCalled) => set({wasCalled}),
}));
