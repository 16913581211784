import {post} from "../apiClient";
import {ChestResult, LevelUpReward, UserState} from "./model";

interface TapProps {
    uid: string,
    cnt: number
}

export async function tapHandler(props: TapProps) {
    const response = await post({
        input: '/api/play/tap',
        init: {
            headers: {
                'Content-Type': 'application/json',
            }
        },
        data: {
            uid: props.uid,
            cnt: props.cnt
        }
    });
    return (await response.json()) as TapResponse
}

interface TapResponse {
    result: {
        "current_tap_cnt": number;
        "coin_cnt": number;
        "level_info": {
            "level": number;
            "exp": number;
            "exp_to": number;
        }
        levelup_rewards: LevelUpReward[]
        user_chest: ChestResult
        user_state?: UserState
    }

}


interface UnlockProps {
    uid: string
}

export async function unlockHandler(props: UnlockProps) {
    const response = await post({
        input: '/api/play/unlock',
        init: {
            headers: {
                'Content-Type': 'application/json',
            }
        },
        data: {
            uid: props.uid
        }
    });
    return (await response.json()) as UnlockResponse;
}

interface UnlockResponse {
    result: {
        coin_cnt: number;
        user_chest: ChestResult
    }
}
