import { create } from 'zustand';
import {Chest, ChestResult} from "../api/requests/model";

type ChestsState = {
    chests: ChestResult[] | null;
    activeChestId: string | null;
    setChests: (chests:(prev: ChestResult[] | null) => ChestResult[] | null) => void;
    setActiveChest: (id: string | null) => void;
    isFirstAdd: boolean;
    setIsFirstAdd: (isFirstAdd: boolean) => void;
};

export const useChestStore = create<ChestsState>((set) => ({
    chests: null,
    activeChestId: null,
    setChests: (chests) => set((state) => ({chests: chests(state.chests)})),
    setActiveChest: (id) => set({ activeChestId: id }),
    isFirstAdd: false,
    setIsFirstAdd: (isFirstAdd) => set({ isFirstAdd: isFirstAdd }),
}));
