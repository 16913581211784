import styled, {keyframes} from "styled-components";
import {ItemBox} from "./icons/item-box";
import {ShineBG} from "./icons/shine-bg";
import {Shine} from "./icons/shine";
import {BoxBG} from "./icons/box-bg";
import {Tail} from "./icons/tail";
import React from "react";
import {getTailRotation} from "../utils/getTailRation";
import {useWidth} from "../hooks/useWidth";
import {Bag, Chest} from "../api/requests/model";
import {ItemInfoModal} from "./item-info-modal";

interface Props {
    image?: string;
    onClick?: (element: HTMLDivElement | null) => void;
    isSelected?: boolean;
    count?: number;

}

export const BagItem = (props: Props) => {
    const WIDTH = useWidth()

    return (
        <Relative onClick={(e) => {
            if (props.onClick) {
                props.onClick(e.currentTarget)
            }
        }
        }
        >


            {props.isSelected &&
                <Border
                    style={{
                        width: WIDTH / 3,
                        height: WIDTH / 3,
                        position: 'absolute',

                    }}/>
            }
            <ItemBox
                style={{
                    width: WIDTH / 3,
                    height: WIDTH / 3,
                    borderColor: 'red',
                    borderWidth: '20px',
                }}
            />
            {props?.image &&
                <div style={{
                    zIndex: 1,
                    position: "absolute",
                    top: '7%',
                    left: '6%',
                }}>
                    <img
                        style={{
                            width: WIDTH / 3.5,
                            height: WIDTH / 3.5,
                        }}
                        src={props.image} alt={'chest'}/>

                </div>
            }
            {(props.count && props.count > 1 && props.count != 3) ?
                <Count>
                    x{props.count}
                </Count>
                : null}
        </Relative>
    )
}


const Count = styled.div`
  font-size: 26px;
  font-weight: 500;
  line-height: 31.2px;
  text-align: center;
  position: absolute;
  z-index: 100;
  color: white;
  bottom: 11px;
  right: 11px;
`

const Relative = styled.div`
  position: relative;
`


const Border = (props: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg" width="105" height="105" viewBox="0 0 105 105" fill="none">
            <path
                d="M101.891 93.7748C101.53 97.6413 97.967 101.2 94.1151 101.553C66.2815 103.698 39.053 103.698 11.2291 101.553C7.36257 101.191 3.80361 97.6283 3.45121 93.7763C1.30626 65.9428 1.30622 38.7144 3.45109 10.8906C3.81289 7.02403 7.37559 3.46506 11.2276 3.11265C39.0611 0.967698 66.2895 0.967662 94.1133 3.11252C97.9797 3.4743 101.539 7.03688 101.891 10.8888C104.036 38.7224 104.036 65.9509 101.891 93.7748Z"
                stroke="url(#paint0_linear_214_49407)" stroke-width="3"/>
            <defs>
                <linearGradient id="paint0_linear_214_49407" x1="52.6712" y1="0.00390625" x2="52.6712" y2="104.661"
                                gradientUnits="userSpaceOnUse">
                    <stop stop-color="#DDA35F"/>
                    <stop offset="0.185" stop-color="#F2EDB9"/>
                    <stop offset="1" stop-color="#693C1D"/>
                </linearGradient>
            </defs>
        </svg>
    )
}