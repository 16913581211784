import useSWR from "swr";
import {fetchSWR, post} from "../apiClient";
import {Response, State} from "./model";


export interface CardResponse {
    "card": {
        "id": number,
        "title": string,
        "art_url": string
    },
    "next_level": {
        "level": number,
        "price": number,
        "coin_reward": number
    },
    cur_level: {
        "level": number,
        "price": number,
        "coin_reward": number
    }
    "is_active": boolean,
}


export const useCardList = (skip?: boolean) => {
    return useSWR(skip ? undefined :
            '/api/user/card/list',
        (url: any) => fetchSWR<Response<CardResponse[]>>({input: url})
    );
}

export async function buyCard(id: number) {
    const response = await post({
        input: ':/api/user/card/buy',
        init: {
            headers: {
                'Content-Type': 'application/json',
            }
        },
        data: {
            card_id: id
        }
    });
    return (await response.json()) as Response<Buy>;
}


interface Buy {
    "coin_reward": number,
    "coin_cnt": number,
}

