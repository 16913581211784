import styled from "styled-components";
import {Profile} from "../components/icons/profile";
import React from "react";
import {ChestInfo, useSetUp} from "../api/requests/user-info";
import {Header} from "../components/header";
import {useMeStore} from "../store/me-store";
import {useNavigate} from "react-router-dom";
import {Button} from "../components/button";
import {Loader} from "../components/loader";
import {useSendClicksOnEveryPage} from "../hooks/useSendClicksOnEveryPage";

export const ChestsInfo = () => {
    const {user} = useMeStore()
    const nav = useNavigate()
    useSendClicksOnEveryPage()

    const {data, isLoading} = useSetUp()

    if (isLoading) {
        return <Loader/>
    }

    return (
        <Container>
            <Header user={user}/>
            <AllWrapper>
                <Txt>Чем более редкий сундук ты открываешь, тем более ценную награду получишь</Txt>
                <Wrapper>
                    {data?.result?.chests.sort((a, b) => a.id - b.id).map((chest, index) => (
                        <InfoItem key={index} chestInfo={chest}/>
                    ))}
                    <Button style={{marginTop: 8}} onClick={() => nav('/')}>
                        Продолжить
                    </Button>
                </Wrapper>
            </AllWrapper>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    height: 100vh;
    scrollbar-width: none;
    overflow: hidden;
    width: 100%;
    background-image: url('/bg/main-bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    position: relative;
    box-sizing: border-box;
`;


const Wrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    flex-direction: column;
    gap: 8px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    align-items: center;
    justify-content: center;
    width: 100%;
    justify-items: center; /* Center items horizontally within their columns */
    overflow: auto;
    padding-bottom: 70px;
`

const AllWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 8px 10px 10px 10px;
    height: 100%;
    overflow: hidden;
    width: 100%;
    box-sizing: border-box;
    align-items: center;
    gap: 16px;
`


const Txt = styled.div`
    color: #FFF;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
`


interface Props {
    chestInfo: ChestInfo
}

const InfoItem = ({chestInfo}: Props) => {
    return (
        <ItemContainer>
            <Row>
                <ChestImg src={chestInfo?.art_url} alt={'chest'}/>
                <Column>
                    <Name>
                        {chestInfo?.title}
                    </Name>
                    <CoinRow>
                        Монеты:
                        <Gold>
                            <img
                                style={{width: 14, height: 14}}
                                src={'/images/icon-coin.png'} alt={'coin'}/>
                            {chestInfo?.reward_coin_cnt.min} - {chestInfo?.reward_coin_cnt.max}
                        </Gold>
                    </CoinRow>
                    <CoinRow>
                        Предметы:
                        <span style={{color: 'white'}}>
                          Шанс получения ≈ {chestInfo?.reward_item_chance * 100} %
                        </span>
                    </CoinRow>
                </Column>
            </Row>


        </ItemContainer>
    )
}


const ItemContainer = styled.div`
    display: flex;
    padding: 10px 12px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 16px;
    background: rgba(49, 74, 95, 0.30);
    backdrop-filter: blur(5px);
    width: 100%;
    box-sizing: border-box;
    justify-content: space-between;
`

const Row = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
`


const Column = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 2px;
`

const Name = styled.div`
    color: #FFF;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 133.333% */
`

const CoinRow = styled.div`
    color: #a2abb2;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
    gap: 8px;
    display: flex;
    align-items: center;

`


const ChestImg = styled.img`
    width: 60px;
    height: 60px;



`

const Gold = styled.span`
    color: #FFDA00;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 133.333% */
    display: flex;
    align-items: center;
    gap: 4px;
`

